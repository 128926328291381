import React, { Suspense } from 'react';
import { DefaultLayout } from 'global/layouts';
import { DataContainer } from 'global/components/UI';
import PageHeader from 'global/components/PageHeader';
import { Button, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { settingState, groupsState, leadsState, activitiesState } from 'global/states';
import Loading from 'global/components/Loading';


const ImportExport = (props) => {
  const settings = useRecoilValue(settingState);
  const groups = useRecoilValue(groupsState);
  const leads = useRecoilValue(leadsState);
  const activities = useRecoilValue(activitiesState);

  const downloadData = () => {
    console.log("settings",settings);
    console.log("groups",groups);
    console.log("leads",leads);
    console.log("activities",activities);

    let data = {};
        data.settings = settings;
        data.groups = groups;
        data.leads = leads;
        data.activities = activities;

    let hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data));
    hiddenElement.target = '_blank';
    hiddenElement.download = 'leadmanager.json';
    hiddenElement.click();

    message.success(`Successfully exported all the data.`);
  }



  return (
    <DefaultLayout
      header={
        <PageHeader
          title="Import/Export Data"
          subTitle="Import/Export all the data from here"
        />
      }
    >

      <DataContainer
        title="Import/Export All Data Present In The Lead Manager Application"
        text="Currently we only support exporting of data, In future we will be adding support to import data as well."
        extra={
          <div>
            <Button type="primary" icon={<DownloadOutlined />} onClick={downloadData} block>Export All Data</Button>
          </div>
        }
      />

    </DefaultLayout>
  )

}



const ImportExportSuspense = (props) => {
  return (
    <Suspense fallback={<DefaultLayout><Loading message="Loading All Data..." /></DefaultLayout>}>
      <ImportExport {...props} />
    </Suspense>
  )
}

export default ImportExportSuspense;



/*
            <Button type="primary">Import Leads</Button>

*/
