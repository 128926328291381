/* const firebaseConfig = {
  apiKey: "AIzaSyCQ1IFIztgc_09eHsuJUu9sMxUjtC4LXlA",
  authDomain: "lead-manager-react.firebaseapp.com",
  databaseURL: "https://lead-manager-react.firebaseio.com",
  projectId: "lead-manager-react",
  storageBucket: "lead-manager-react.appspot.com",
  messagingSenderId: "1091815738357",
  appId: "1:1091815738357:web:d8c49b6759b759324b6ebc"
}; */

const firebaseConfig = {
  apiKey: "AIzaSyBiqfqJf86AWAZjP6TXGtPNTYoKtqU6iLQ",
  authDomain: "mcr-leadmanager.firebaseapp.com",
  projectId: "mcr-leadmanager",
  storageBucket: "mcr-leadmanager.appspot.com",
  messagingSenderId: "615917543846",
  appId: "1:615917543846:web:5176fb342652969cbf6f67",
  measurementId: "G-VGMK32P3SC"
};

export default firebaseConfig;
