import React from 'react';
import styled from 'styled-components';
import Api from 'global/api';
import { Link, NavLink } from 'react-router-dom';
import { Menu, Dropdown, Avatar } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { userState } from 'global/states';


const Header = (props) => {
  const sidebar = props.sidebar;
  const user = useRecoilValue(userState);

  const userMenu = (
    <Menu style={{ marginTop: 10 }}>
      <Menu.Item key="profile"><NavLink to="/account/profile">Manage Profile</NavLink></Menu.Item>
      <Menu.Item key="settings"><NavLink to="/settings">Settings</NavLink></Menu.Item>
      <Menu.Divider />
      <Menu.Item key="support"><NavLink to="/account/support">Support</NavLink></Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" onClick={ () => { Api.logout() } }>Logout</Menu.Item>
    </Menu>
  );

  return (
    <HeaderWrapper className={ sidebar ? 'has-sidebar' : '' }>
    <HeaderInner>

      <nav className="fl-links-left">
        { sidebar ? <MenuFoldOutlined onClick={ () => { props.toggleSidebar(); } } /> : <MenuUnfoldOutlined onClick={ () => { props.toggleSidebar(); } } /> }
      </nav>

      <div className="app-name">
        <Link to="/">Lead Manager</Link>
      </div>

      <nav className="fl-links-right">
        <Dropdown overlay={ userMenu } trigger={['click']} placement="bottomRight">
          <Link to="/" className="ant-dropdown-link">
            <Avatar style={{ backgroundColor: '#87d068' }} size={24} icon={<UserOutlined />} />{ user.name || 'Administrator' } <DownOutlined />
          </Link>
        </Dropdown>
      </nav>

    </HeaderInner>
    </HeaderWrapper>
  )

}


Header.whyDidYouRender = true

export default Header;


const HeaderWrapper = styled.div`
  position: fixed;
  height: 50px;
  background-color: #FFF;
  top: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  padding: 0 7px;
  z-index: 100;
  transition: all .3s ease;

  &::after {
    content: '';
    background: radial-gradient(farthest-side at 50% 0%, rgba(36, 18, 77, 0.3), rgba(36, 18, 77, 0.05)) 0 0;
    height: 6px;
    left: 0;
    position: absolute;
    right: 0;
    transition: opacity 250ms cubic-bezier(.2, .45, 0, 1);
    width: 100%;
  }

  .app-name {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    line-height: 20px;
    padding: 15px 0;
    overflow: hidden;
    flex: 1 1 0%;
    a {
      color: #000;
      text-decoration: none;
    }
    &.active { color: #000;  }
  }

  nav {
    align-self: center;
    padding: 0 15px;
    flex: 1;
    display: flex;

    &.fl-links-left {
      justify-content: flex-start;
    }
    &.fl-links-right {
      justify-content: flex-end;
      overflow: hidden;
    }
  }

  .ant-dropdown-link {
    align-self: center;
    padding: 0 15px;
    font-size: 14px;
    font-weight: bold;
    color: #000;
  }
  .ant-avatar { margin-right: 5px; }

  &.has-sidebar {
    margin-left: 260px;;
  }

`;


const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  align-items: center;
`;
