import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import firebaseConfig from './config';
import { message } from 'antd';

class FireBase {

  constructor() {
    if (firebase.apps.length === 0) { firebase.initializeApp(firebaseConfig); }
    this.firebase = firebase;
    this.firestore = this.firebase.firestore();
    this.auth = this.firebase.auth();
    // this.storage = this.firebase.storage();
  }

  auth() {
    return this.auth;
  }


  // handle user login
  async login(email, password) {
    try {
      const res = await this.firebase.auth().signInWithEmailAndPassword(email, password);
      const data = await this.getRecord('users', res.user.uid );
      const setting = await this.fetchSettings();
      message.success(`You're successfully logged in.`);
      return {
        user: { ...data, id: res.user.uid },
        setting: setting,
      };
    } catch (err) {
      message.error(`Please enter a valid email address and password.`);
      return false;
    }
  }

  async logout() {
    await this.firebase.auth().signOut().then( data => {
      message.success( `You're successfully logged out.`);
    })
    .catch( err => {
      message.error(`Error Occurred: ${ err.message }`);
    });
  }

  async resetPassword(password) {
    const user = this.firebase.auth().currentUser;

    await user.updatePassword(password).then( data => {
      message.success( `Your password has beens successfully updated.`);
    })
    .catch( err => {
      message.error(`Error Occurred: ${ err.message }`);
    });
  }

  async updateSettings(data) {
    try {
      await this.saveRecordWithId( 'settings', 'general', data);
      message.success(`Settings have been successfully updated.`);
      return true;
    } catch (err) {
      message.error(`Error Occurred: ${ err.message }`);
      return false;
    }
  }


  async saveGroup(data, id) {
    if ( id ) {
      const res = await this.saveRecordWithId( 'groups', id, { ...data, updatedAt: new Date() } );
      return res;
    } else {
      const res = await this.createRecord( 'groups', data );
      return res;
    }
  }

  async saveLead(data, id) {
    if ( id ) {
      console.log("saveLead:::datadatadata",data);
      const res = await this.saveRecordWithId( 'leads', id, { ...data, updatedAt: new Date() } );
      return res;
    } else {
      const res = await this.createRecord( 'leads', data );
      return res;
    }
  }

  async saveActivity(data, id) {
    if ( id ) {
      const res = await this.saveRecordWithId( 'activities', id, { ...data, updatedAt: new Date() } );
      return res;
    } else {
      const res = await this.createRecord( 'activities', data );
      return res;
    }
  }

  // async saveActivity( leadId, data, id=null ) {
  //   if ( id ) {
  //     const activitiesCollection = this.firestore.collection('leads').doc(leadId).collection('activities').doc(id);
  //     const res = await activitiesCollection.add({ ...data, updatedAt: new Date() } );
  //     return res;
  //   } else {
  //     const activitiesCollection = this.firestore.collection('leads').doc(leadId).collection('activities');
  //     const res = await activitiesCollection.add({ ...data, createdAt: new Date() } );
  //     return res;
  //   }
  // }

  async fetchUserData(userId) {
    const uid = userId || this.uid;
    const data = await this.getRecord('users', uid);
    return data;
  }
  async fetchSettings() {
    const data = await this.getRecord('settings', 'general');
    return data;
  }

  // when the user authentication status changes.
  async onAuthStateChanged( cb ) {
    await this.firebase.auth().onAuthStateChanged( user => {
      if ( user ) { cb(user); }
      else { cb(false); }
    });
  }

  async saveRecordWithId( collection, id, data ) {
    // const data = { ..._data, createdAt: new Date() }
    const coll = this.firestore.collection(collection);
    const res = await coll.doc(id).set(data, { merge: true });
    return res;
  }

  async createRecord( collection, _data ) {
    const data = { ..._data, createdAt: new Date() }
    const res = await this.firestore.collection(collection).add(data);
    return res;
  }

  async getRecord( collection, id ) {
    const doc = this.firestore.collection(collection).doc(id);
    const res = await doc.get();
    if ( res.exists ) { return res.data(); }
    else { return {}; }
  }

  async updateRecord( collection, id, data ) {
    const coll = this.firestore.collection(collection);
    const res = await coll.doc(id).update({ ...data, updatedAt: new Date() });
    return res;
  }

  async updateProfile(data) {
    const uid = this.uid;
    await this.updateRecord('users', uid, data);
    return true;
  }


  async findAllRecords(collection) {
    let snapshot = null;
    if ( collection === 'leads' ) { snapshot = await this.firestore.collection(collection).orderBy('createdAt', 'desc').get(); }
    else { snapshot = await this.firestore.collection(collection).orderBy('createdAt', 'asc').get(); }

    if (snapshot.empty) {
      return [];
    }
    const records = [];
    snapshot.docs.forEach( doc => {
      records.push({ ...doc.data(), id: doc.id });
    });
    return records;
  }

  async deleteRecord( collection, id ) {
    try {
      await this.firestore.collection(collection).doc(id).delete();
      if ( collection === 'leads' ) {
        await this.batchDeleteActivities(id);
      }
      return true;
    } catch(err) {
      message.error(`Error Occurred: ${ err.message }`);
      return false;
    }
  }


  async batchDeleteActivities( leadId ) {
    try {
      const snapshot = await this.firestore.collection('activities').where('lead', '==', leadId).get();
      const batch = this.firestore.batch();
      snapshot.docs.forEach( doc => {
        batch.delete(doc.ref);
      });
      return batch.commit();
    } catch(err) {
      message.error(`Error Occurred: ${ err.message }`);
    }
  }




  // async signup( email, password, data ) {
  //   try {
  //     // create new user
  //     const res = await this.firebase.auth().createUserWithEmailAndPassword(email, password);
  //     const userId = res.user.uid;

  //     // create user profile and add additional details
  //     const profile = await this.saveRecordWithId( 'users', userId, {
  //       uid: userId,
  //       email: data.email,
  //       first_name: data.first_name,
  //       last_name: data.last_name,
  //       createdAt: new Date(),
  //     });

  //     // show success toast
  //     message.success(`Your account has been successfully created.`);
  //     return true;
  //   }
  //   catch (err) {
  //     message.error(`Error Occurred: ${ err.message }`);
  //     return false;
  //   }
  // }

}


const Api = new FireBase();
export default Api;
