import React, { Fragment } from 'react';
import { NavLink, Link, withRouter } from 'react-router-dom';
import _ from 'lodash';
import Icon, { HomeOutlined, TeamOutlined, SettingOutlined, PartitionOutlined, CalendarOutlined, ImportOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import styled from 'styled-components';
import { useRecoilValue } from 'recoil';
import { settingState } from 'global/states';



const CompanyName = ( props ) => {
  const setting = useRecoilValue(settingState);
  return (
    <div className="logo">
      <Link to="/">{ setting && setting.company ? setting.company : 'Welcome!' }</Link>
    </div>
  )
}


// the main navigation aside/sidebar
const navigation = [
  {
    id: 'home',
    title: 'Home',
    url: '/',
    icon: HomeOutlined,
  },
  {
    id: 'leads',
    title: 'Leads',
    url: '/leads',
    icon: TeamOutlined,
    items: [
      {
        id: 'data-list',
        title: 'All Leads',
        url: '/leads/all',
      },
      {
        id: 'data-card',
        title: 'Add New Lead',
        url: '/leads/create',
      },
    ],
    active: [ 'all', 'create' ],
  },
  {
    id: 'groups',
    title: 'Groups',
    url: '/groups',
    icon: PartitionOutlined,
    defaultActive: true,
  },
  {
    id: 'calendar',
    title: 'Calendar',
    url: '/calendar',
    icon: CalendarOutlined,
    defaultActive: true,
  },
  {
    id: 'import-export',
    title: 'Import/Export',
    url: '/import-export',
    icon: ImportOutlined,
    defaultActive: true,
  },  {
    id: 'settings',
    title: 'Settings',
    url: '/settings',
    icon: SettingOutlined,
    defaultActive: true,
  },

];





class Aside extends React.Component {

  constructor() {
    super();
    this.state = {
      openKeys: [],
      current: '',
    };
    this.handleClick = this.handleClick.bind(this);
    this.processURLs = this.processURLs.bind(this);
    this.onOpenChange = this.onOpenChange.bind(this);
  }

  componentWillMount() {
    this.processURLs( this.props.location.pathname );
  }
  componentWillReceiveProps( nextProps ) {
    this.processURLs( nextProps.location.pathname );
  }

  handleClick(e) {
    // console.log("handleClick",e);
    this.setState({
      current: e.key,
      openKeys: e.keyPath.slice(1),
    });
  }


  processURLs(url) {
    let openKeys = [];
    let current = url;

    const split = url.split('/');
    const curr = _.find( navigation, { id: split[1] } );
    const navLinksToExclude = (curr && curr.active) || [];


    const loc = url;
    // console.log("loc",loc);
    const splittedLoc = loc.split('/');
    // console.log("splittedLoc",splittedLoc);
    if ( splittedLoc.length >= 2 ) {
      let output = "";
      if ( splittedLoc[0] === "" ) { output = "/"; }
      if ( splittedLoc[1] !== "" ) { output = output+splittedLoc[1]; }
      openKeys.push(output);
    }

    if ( curr && curr.active && curr && curr.active.length > 0 && ! navLinksToExclude.includes(splittedLoc[2]) ) {
      current = `/${splittedLoc[1]}/all`;
    }
    if ( curr && curr.defaultActive ) { current = `/${splittedLoc[1]}`; }

    this.setState({
      openKeys: openKeys,
      current: current
    });
  }

  onOpenChange(info) {
    if ( ! info || info.length <= 0 ) {
      this.setState({ openKeys: [] });
      return;
    }
    const lastEl = info[info.length-1];
    let openKeys = [ lastEl ];
    this.setState({ openKeys: openKeys });
  }


  renderMenu(menuitems) {
    if ( ! menuitems ) return null;
    return (
      <Menu
        onClick={this.handleClick}
        defaultSelectedKeys={['/']}
        mode="inline"
        className="aside--nav"
        onOpenChange={ this.onOpenChange }
        openKeys={this.state.openKeys}
        selectedKeys={[this.state.current]}
        theme="dark"
        multiple={false}
      >

        { menuitems &&

          menuitems.map( (item) => {
            if ( item.url && ! item.items ) {
              return (
                <Menu.Item key={item.url}>
                  <NavLink to={item.url} activeClassName="active"><Icon component={item.icon}/><span>{item.title}</span></NavLink>
                </Menu.Item>
              )
            }
            else if ( item.items && item.items.length > 0 ) {
              return (
                <Menu.SubMenu key={item.url} title={<span><Icon component={item.icon} /><span>{item.title}</span></span>}>
                { item.items.map( sub => {
                  return (
                    <Menu.Item key={ sub.url }>
                      <NavLink to={ sub.url }>{sub.title}</NavLink>
                    </Menu.Item>
                  )
                }) }
                </Menu.SubMenu>
              )
            }
            else {
              return null;
            }
          })

        }

      </Menu>
    )
  }


  render() {

    const SidebarNavigation = (
      <Fragment>
        <CompanyName />
        { this.renderMenu(navigation) }
      </Fragment>
    );

    return (
    <AsideContainer className={ this.props.visible ? 'visible' : 'hidden' }>
      <div className="inner">
        { SidebarNavigation }
      </div>
    </AsideContainer>
    )

  }

}


Aside.whyDidYouRender = true
export default withRouter(Aside);



const AsideContainer = styled.aside`
  width: 260px;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  border-right: 2px solid #9cd3ff;
  z-index: 150;
  background: #001529;


  .logo {
    font-size: 20px;
    line-height: 20px;
    height: 50px;
    display: flex;
    align-items: center;
    background: #002140;
    padding: 0 20px;
    color: #FFF;
    a {
      text-decoration: none;
      color: #FFF;
    }
  }

  .inner {
  }

  .aside--nav {
  }

  &.visible {
    display: block;
    transition: all 0.3s ease;
  }

  &.hidden {
    margin-left: -300px;
    transition: all 0.3s ease;
  }

  .ant-menu-item a { display: flex; align-items: center; }
  .ant-menu-submenu-title span:first-child { display: flex; align-items: center; }

  .ant-menu-item .anticon, .ant-menu-submenu-title .anticon {
    margin-right: 5px;
  }

`;


/*
const Footer = styled.div`
  color: #fff;
  padding: 20px;
  color: rgba(255,255,255,0.5);
  position: absolute;
  bottom: 0;
  left: 0;
  margin-bottom: 10px;
`;

      <Footer>
        <Divider style={{ background: 'rgba(255,255,255,0.3' }} />
        <div>Copyright 2020, Lead Manager. All Rights Reserved.</div>
      </Footer>
*/
