import {
  atom,
  selector,
} from 'recoil';
import Api from 'global/api';



// for sidebar status
export const sidebarVisible = atom({
  key: 'sidebarVisible',
  default: localStorage.getItem('sidebar') === "false" ? false : true,
});



// let's get the user data from localStorage and delete it after reading.
const userDataQuery = selector({
  key: 'userDataQuery',
  get: () => {
    const userData = localStorage.getItem('userData');
    let userDataParsed = {};
    if ( userData ) {
      userDataParsed = JSON.parse(userData);
      localStorage.removeItem('userData');
    }
    return userDataParsed;
  },
});

export const userState = atom({
  key: 'user',
  default: userDataQuery,
});


// let's get the user data from localStorage and delete it after reading.
const settingQuery = selector({
  key: 'settingQuery',
  get: () => {
    const settings = localStorage.getItem('settings');
    let settingsParsed = {};
    if ( settings ) {
      settingsParsed = JSON.parse(settings);
      localStorage.removeItem('settings');
    }
    return settingsParsed;
  },
});


export const settingState = atom({
  key: 'setting',
  default: settingQuery,
});


const groupsQuery = selector({
  key: 'groupsQuery',
  get: async () => {
    const groups = await Api.findAllRecords('groups');
    return groups;
  },
});
export const groupsState = atom({
  key: 'groups',
  default: groupsQuery,
});


const leadsQuery = selector({
  key: 'leadsQuery',
  get: async () => {
    const leads = await Api.findAllRecords('leads');
    return leads;
  },
});
export const leadsState = atom({
  key: 'leads',
  default: leadsQuery,
});


const activitiesQuery = selector({
  key: 'activitiesQuery',
  get: async () => {
    const activities = await Api.findAllRecords('activities');
    return activities;
  },
});
export const activitiesState = atom({
  key: 'activities',
  default: activitiesQuery,
});






