import React, { useState } from 'react';
import styled from 'styled-components';
import { AuthLayout } from 'global/layouts';
import Api from 'global/api';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Spin } from 'antd';
import { MailOutlined, LockOutlined } from '@ant-design/icons';
import { useSetRecoilState } from 'recoil';
import { userState, settingState } from 'global/states';


const LoginPage = (props) => {
  const [ loading, setLoading ] = useState(false);
  const setUser = useSetRecoilState(userState);
  const setSetting = useSetRecoilState(settingState);

  const handleSubmit = async (data) => {
    setLoading(true);
    const res = await Api.login(data.email, data.password);
    if ( ! res ) {
      setLoading(false);
    } else {
      setUser(res.user);
      setSetting(res.setting);
      props.history.push('/');
    }
  }

  return (
    <AuthLayout>

      <Row>
      <Col xs={{ span: 24 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }} xl={{ span: 10, offset: 7 }} xxl={{ span: 6, offset: 9 }}>
        <LoginContainer>
        <Spin tip="Logging you in..." spinning={ loading }>

          <h2>Please enter your email address below.</h2>

          <Form
            layout="vertical"
            name="normal_login"
            onFinish={ handleSubmit }
            // initialValues={{}}
            // onFinishFailed={}
          >

            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                { required: true, message: 'Please enter your email address!' },
                { type: 'email', message: 'Please enter a valid email address!' }
              ]}
            >
              <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email Address" />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[{ required: true, message: 'Please enter your password!' }]}
            >
              <Input.Password
                prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
                placeholder="Password"
              />
            </Form.Item>

            <p>Forgot your password? <Link to ="/auth/reset-password">Reset Password</Link></p>

            <Form.Item>
              <ButtonContainer>
                <Button type="primary" htmlType="submit">Log In</Button>
                {/*<Link to="/auth/signup"><Button type="primary" ghost>Don't have an Account?</Button></Link>*/}
              </ButtonContainer>
            </Form.Item>


          </Form>

        </Spin>
        </LoginContainer>

      </Col>
      </Row>

    </AuthLayout>
  )

}


export default LoginPage;



const LoginContainer = styled.div`
  margin: 0 auto;
  padding: 40px;
  box-shadow: 0 0 0 rgb(235, 255, 212), 0 0 0 rgb(208, 255, 155), 0 20px 20px #b6f7ff;

  @media (max-width: 420px) {
    padding: 30px;
  }

  h2 {
    font-weight: 700;
    margin-bottom: 30px;
    font-size: 28px;
    line-height: 100%;
  }

  .ant-form-item:last-child {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
