import React from 'react';
import styled from 'styled-components';
import { PageHeader } from 'antd';


const PageHeaderComponent = (props) => {

  return (
    <PageHeader
      style={ props.style || {
        border: '1px solid rgb(235, 237, 240)',
        padding: '4px 22px',
      }}
      title={props.title}
      subTitle={props.subTitle}
      extra={ props.buttons ? (
        <ButtonsWrapper>
          { props.buttons }
        </ButtonsWrapper>
        ): null
      }
      tags={ props.tags }
      onBack={ props.onBack }
    />
  )

}

PageHeaderComponent.whyDidYouRender = true

export default PageHeaderComponent;


const ButtonsWrapper = styled.div`
  a { margin-left: 5px; }
  a:first-child { margin-left: 0; }
  button { font-size: 13px; }
`;
