import React from 'react';
import styled from 'styled-components';
import { Row, Col, Card, Tag, Avatar } from 'antd';
import { UserOutlined, MailOutlined, PhoneOutlined, InfoCircleOutlined, CalendarOutlined, PushpinOutlined } from '@ant-design/icons';
import { format, isBefore } from 'date-fns';
import { useHistory } from "react-router-dom";


const Lead = (props) => {
  const history = useHistory();
  const { data } = props;
  const duedate = new Date(data.duedate.seconds * 1000);

  const handleClick = () => {
    history.push(`/leads/${data.id}`);
  }


  return (
    <Col xs={24} md={12} lg={12} xl={8} xxl={6}>
      <LeadWrapper>
      <Card hoverable onClick={ handleClick }>
        <Card.Meta
          avatar={<Avatar size={'medium'} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />}
          title={ data.name }
        />

        <div className="meta-content">
          <p><MailOutlined /> <a href={`mailto:${ data.email }`}>{ data.email }</a></p>
          { data.phone && <p><PhoneOutlined /> { data.phone }</p> }
          { data.status && <p style={{ textTransform: 'capitalize' }}><InfoCircleOutlined /> { data.status }</p> }
          { data.location && <p><PushpinOutlined /> { data.location }</p> }

          <div>
            <CalendarOutlined />
            <Tag
              style={{ fontSize: 14, textTransform: 'capitalize' }}
              color={ isBefore( duedate, new Date() ) ? 'red' : 'blue' }
            >{ format(duedate, 'do MMM, yyyy') }</Tag>
          </div>

        </div>

      </Card>
      </LeadWrapper>
    </Col>
  )
}

const DefaultList = (props) => {
  const { leads } = props;

  return (
    <Row gutter={[20, 20]}>
      { leads.map( lead => {
        return <Lead key={lead.id} data={lead} />
      }) }
    </Row>
  )

}


const GroupedList = (props) => {
  const { leads, groups } = props;
  const filteredGroups = leads.reduce( (a, b) => {
    a[b.group] = [...a[b.group] || [], b];
    return a;
  }, {});

  return (
    <div>
      { filteredGroups && Object.keys(filteredGroups).length > 0 && Object.keys(filteredGroups).map( group => {
        const allLeads = filteredGroups[group];
        const currentGroup = groups.find( data => data.id === group ) || {};
        return (
          <GroupContainer key={group}>
            <Title>{ currentGroup.name || 'Group Not Found' }</Title>
            <Row gutter={[20, 20]}>
              { allLeads.map( lead => {
                return <Lead key={lead.id} data={lead} />
              }) }
            </Row>
          </GroupContainer>
        )
      }) }
    </div>
  )

}


const StatusList = (props) => {
  const { leads } = props;
  const filteredStatus = leads.reduce( (a, b) => {
    a[b.status] = [...a[b.status] || [], b];
    return a;
  }, {});

  return (
    <div>
      { filteredStatus && Object.keys(filteredStatus).length > 0 && Object.keys(filteredStatus).map( status => {
        const allLeads = filteredStatus[status];
        return (
          <GroupContainer key={status}>
            <Title>{ status || 'Undefined' }</Title>
            <Row gutter={[20, 20]}>
              { allLeads.map( lead => {
                return <Lead key={lead.id} data={lead} />
              }) }
            </Row>
          </GroupContainer>
        )
      }) }
    </div>
  )

}


export {
  DefaultList,
  GroupedList,
  StatusList
};

export default DefaultList;

const LeadWrapper = styled.div`
  .ant-card-meta {
    margin-bottom: 20px;
    display: flex;
    .ant-card-meta-detail { align-self: center; }
  }

  .meta-content {
    .anticon { margin-right: 10px; }
    p {
      margin-bottom: 4px;
    }
  }

`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 20px;
  text-transform: capitalize;
`;

const GroupContainer = styled.div`
  margin-bottom: 40px;
  &:last-child { margin-bottom: 0; }
`;


