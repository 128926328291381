import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';



const AuthLayout = (props) => (
  <div
    className=""
    style={ props.style || {} }
  >

    <AppHeader>
      <div className="app-name">
        <Link to="/auth/login">Lead Manager</Link>
      </div>
    </AppHeader>

    <AuthWrapper>
      <AuthInner>
        { props.children }
      </AuthInner>
    </AuthWrapper>

  </div>
)

export default AuthLayout;



const AppHeader = styled.div`
  position: fixed;
  height: 50px;
  background-color: #FFF;
  top: 0;
  left: 0;
  right: 0;
  justify-content: space-between;
  padding: 0 7px;
  z-index: 100;
  transition: all .3s ease;

  &::after {
    content: '';
    background: radial-gradient(farthest-side at 50% 0%, rgba(36, 18, 77, 0.3), rgba(36, 18, 77, 0.05)) 0 0;
    height: 9px;
    left: 0;
    position: absolute;
    right: 0;
    transition: opacity 250ms cubic-bezier(.2, .45, 0, 1);
    width: 100%;
  }

  .app-name {
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    line-height: 50px;
    overflow: hidden;
    flex: 1 1 0%;
    a {
      color: #000;
      text-decoration: none;
    }
    &.active { color: #000;  }
  }

`;



const AuthWrapper = styled.div`
  padding-top: 60px;
  transition: all .3s ease;
`;

const AuthInner = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 50px;

  @media (max-width: 420px) {
    padding: 30px;
  }

`;
