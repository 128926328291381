import React, { Suspense, useState } from 'react';
import { DefaultLayout } from 'global/layouts';
import { DataContainer } from 'global/components/UI';
import PageHeader from 'global/components/PageHeader';
import Loading from 'global/components/Loading';
import { PlusOutlined } from '@ant-design/icons';

import { useRecoilValue } from 'recoil';
import { groupsState } from 'global/states';

import { Row, Col, Button } from 'antd';

import GroupList from './GroupList';
import SaveGroup from './Save';


const GroupsData = (props) => {
  const groups = useRecoilValue(groupsState);

  if ( groups.length === 0 ) {
    return (
      <DataContainer
        title="All Groups"
        text="You haven't created any groups yet. As soon as you create any group it will appear here."
        extra={ <Button key="createGroup" type="primary" icon={<PlusOutlined />} onClick={ () => { props.create(true) } }>Create New Group</Button> }
      />
    )
  } else {
    return (
      <Row>
      <Col xs={{ span: 24 }} md={{ span: 20, offset: 2 }} lg={{ span: 18, offset: 3 }} xl={{ span: 16, offset: 4 }} xxl={{ span: 14, offset: 5 }}>
        <GroupList data={ groups } />
      </Col>
      </Row>
    )
  }

}



const AllGroups = (props) => {
  const createGroup = props && props.history && props.history.location && props.history.location.state && props.history.location.state.create ? props.history.location.state.create : false;
  const [ showCreateGroupModal, setShowCreateGroupModal ] = useState(createGroup);
  const hideCreateGroupModal = () => { setShowCreateGroupModal(false); }


  return (
    <DefaultLayout
      header={
        <PageHeader
          title="Groups"
          subTitle="All Groups"
          onBack={ () => { props.history.push('/') } }
          buttons={[
            <Button key="createGroup" type="primary" icon={<PlusOutlined />} onClick={ () => { setShowCreateGroupModal(true) } }>Create New Group</Button>
          ]}
        />
      }
    >

      <Suspense fallback={<Loading message="Loading Groups..." />}>
        <GroupsData create={setShowCreateGroupModal} />
      </Suspense>

      { showCreateGroupModal &&
        <SaveGroup show={showCreateGroupModal} hide={hideCreateGroupModal} group={{}} />
      }

    </DefaultLayout>
  )

}


const GroupSuspense = (props) => {
  return (
    <Suspense fallback={<DefaultLayout><Loading message="Loading Group..." /></DefaultLayout>}>
      <AllGroups {...props} />
    </Suspense>
  )
}

export default GroupSuspense;
