import React, { useState } from 'react';
import styled from 'styled-components';
import { Timeline, Button, Tag } from 'antd';

import Modal from 'global/components/Modal';
import Api from 'global/api';
import { Form, Input, Select, message, Spin, Modal as AntdModal } from 'antd';
import DatePicker from 'global/components/UI/DatePicker';

import { useRecoilState } from 'recoil';
import { activitiesState } from 'global/states';
import { format, toDate } from 'date-fns';
import { EditOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';



const activityTypes = [
  'Call',
  'Email',
  'Meeting',
  'Message',
  'Office Visit',
  'Other',
];



const CreateActivityModal = props => {
  const { lead, activity } = props;
  const [ loading, setLoading ] = useState(false);
  const [ activities, setActivities ] = useRecoilState(activitiesState);

  const handleSubmit = async (values) => {
    let data = { ...values };
    data['date'] = toDate(data['date']);
    data['lead'] = lead.id;

    setLoading(true);
    const res = await Api.saveActivity( data );
    const id = activity.id || null;

    data.date = { seconds: Math.floor(data.date / 1000) }

    if ( id ) {
      const allActivities = [...activities ];
      const index = allActivities.findIndex( _activity => _activity.id === id );
      allActivities[index] = { ...data, id: id }
      setActivities(allActivities);
      message.success('Activity has been successfully updated.');
    } else {
      const allActivities = [...activities, { ...data, id: res.id }];
      setActivities(allActivities);
      message.success('New activity has been successfully added.');
    }

    setLoading(false);
    props.hide();
  }

  const initialValues = activity.id ? { ...activity, date: new Date(activity.date.seconds * 1000) } : {};

  return (
    <Modal
      title={ props.activity.id ? 'Update Activity' : 'Add New Activity' }
      subtitle="Please enter details below to add/update activity."
      visible={ props.show }
      onOk={ props.hide }
      onCancel={ props.hide }
      footer={null}
    >
      <Spin spinning={loading} tip={ props.activity.id ? 'Updating activity...' : 'Adding activity...' } size="large">
      <div className="form--default">
      <Form name="create-activity" colon={false} onFinish={handleSubmit} initialValues={initialValues}>

        <Form.Item
          name="type"
          label="Activity Type"
          rules={[
            { required: true, message: 'Please select activity type' },
          ]}
        >
          <Select placeholder="Activity Type" showSearch allowClear>
            { activityTypes.map( at => <Select.Option key={at} value={at}>{at}</Select.Option> )}
          </Select>
        </Form.Item>

        <Form.Item
          label="Activity Description"
          name="description"
          rules={[
            { required: true, message: 'Please enter activity description' },
          ]}
        >
          <Input.TextArea rows={3} placeholder="Enter some description" />
        </Form.Item>

        <Form.Item
          name="date"
          label="Date"
          rules={[
            { required: true, message: 'Please select date of this activity' },
          ]}
        >
          <DatePicker placeholder="Select Date" style={{ width: 260 }} />
        </Form.Item>

        <div className="footer right-aligned">
          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit" block>{ props.activity.id ? 'Update Activity' : 'Add Activity' }</Button>
          </Form.Item>
        </div>

      </Form>
      </div>
      </Spin>
    </Modal>
  )

}




function confirmActivityDelete(id, activities, setActivity) {
  AntdModal.confirm({
    title: 'Are you sure?',
    icon: <ExclamationCircleOutlined />,
    content: 'Once deleted you can never recover this activity again.',
    okType: 'danger',
    okText: 'Yes, Delete',
    onOk() {
      return new Promise( async (resolve, reject) => {
        const res = await Api.deleteRecord('activities', id);
        if ( res ) {
          const allActivities = [...activities];
          const updatedActivities = allActivities.filter( activity => activity.id !== id );
          setActivity(updatedActivities);
          resolve(res);
          message.success(`Activity has been successfully deleted.`);
        } else {
          reject();
        }
      }).catch( () => console.log('Error !'));
    },
    onCancel() {},
  });
}


const Activities = (props) => {
  const [ allActivities, setAllActivities ] = useRecoilState(activitiesState);
  const { lead } = props;

  const activities = allActivities.filter( act => act.lead === lead.id );
  const sortedActivities = activities.sort( (x,y) => y.date.seconds - x.date.seconds );


  const [ showAddActivityModal, setShowAddActivityModal ] = useState(false);
  const [ selectedActivity, setSelectedActivity ] = useState(null);
  const hideCreateActivityModal = () => { setShowAddActivityModal(false); }


  return (
    <TimelineWrapper>

      <Timeline mode={ sortedActivities.length > 0 ? 'alternate' : 'left' }>

        <Timeline.Item key="add">
          <TimelineCard>
            <div className="description">
              <Button type="primary" block onClick={ () => { setSelectedActivity(null); setShowAddActivityModal(true) } }>Add New Activity</Button>
            </div>
          </TimelineCard>
        </Timeline.Item>

        { sortedActivities && sortedActivities.map( activity => {
          return (
          <Timeline.Item key={activity.id}>
            <TimelineCard>
              <div className="title">{ activity.type }</div>
              <div className="date"><Tag color="geekblue">{ format( new Date(activity.date.seconds * 1000), 'do MMM, yyyy' ) }</Tag></div>
              <div className="description">{ activity.description }</div>

              <div className="actions">
                <Button size="small" icon={<EditOutlined />} type="primary" onClick={ () => { setSelectedActivity(activity); setShowAddActivityModal(true) } }>Update</Button>
                <Button size="small" icon={<DeleteOutlined />} ghost type="danger" style={{ marginLeft: 10 }} onClick={ () => { confirmActivityDelete(activity.id, allActivities, setAllActivities) } }>Delete</Button>
              </div>

            </TimelineCard>
          </Timeline.Item>
          )
        }) }

      </Timeline>


      { showAddActivityModal && <CreateActivityModal lead={lead} show={showAddActivityModal} hide={hideCreateActivityModal} activity={ selectedActivity || {} } /> }


    </TimelineWrapper>
  )

}


export default Activities;


const TimelineWrapper = styled.div`
  padding-top: 20px;

  .ant-timeline.ant-timeline-alternate .ant-timeline-item-right .ant-timeline-item-content, .ant-timeline.ant-timeline-right .ant-timeline-item-right .ant-timeline-item-content {
    right: 0;
    text-align: left;
    left: calc( 50% - 340px );
  }
  .ant-timeline.ant-timeline-alternate { max-width: 100% !important; }
`;


const TimelineCard = styled.div`
  padding: 20px;
  border: 1px solid #000;
  border-radius: 10px;
  max-width: 300px;

  .title {
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #CCC;
    padding-bottom: 10px;
    text-transform: capitalize;
  }

  .date {
    font-size: 14px;
    color: #999;
    margin: 10px 0;
  }

  .description {
    font-size: 14px;
  }

  .actions {
    margin-top: 20px;
  }

`;




