import React from 'react';
import styled from 'styled-components';
import { AuthLayout } from 'global/layouts';
import { Link } from 'react-router-dom';
import { Button } from 'antd';


const LogoutPage = (props) => {

  return (
    <AuthLayout>
      <LoginContainer>
        <h2>You have been successfully logged out.</h2>
        <Button type="primary" ghost><Link to="/auth/login">Login Again</Link></Button>
      </LoginContainer>
    </AuthLayout>
  )

}


export default LogoutPage;


const LoginContainer = styled.div`
  margin: 0 auto;
  padding: 40px;
  box-shadow: 0 0 0 rgb(235, 255, 212), 0 0 0 rgb(208, 255, 155), 0 20px 20px #b6f7ff;
  max-width: 500px;

  @media (max-width: 420px) {
    padding: 30px;
  }

  h2 {
    font-weight: 700;
    margin-bottom: 30px;
    font-size: 28px;
    line-height: 100%;
  }

`;
