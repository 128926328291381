import React, { useState } from 'react';
import styled from 'styled-components';
import { DefaultLayout } from 'global/layouts';
import { Row, Col, Form, Input, Select, Button, Divider, Spin } from 'antd';
import PageHeader from 'global/components/PageHeader';
import Api from 'global/api';
import { useRecoilState } from 'recoil';
import { settingState } from 'global/states';


const Settings = (props) => {

  const [ loading, setLoading ] = useState(false);
  const [ setting, setSetting ] = useRecoilState(settingState);

  const onFinish = async (values) => {
    setLoading(true);
    setSetting(values);
    await Api.updateSettings(values);
    setLoading(false);
  }


  return (
    <DefaultLayout
      header={
        <PageHeader
          title="Settings"
          subTitle="Manage all the settings from here!"
          onBack={ () => { props.history.push('/') } }
        />
      }
    >

      <Row>
      <Col xs={{ span: 24 }} md={{ span: 18, offset: 3 }} lg={{ span: 16, offset: 4 }} xl={{ span: 14, offset: 5 }} xxl={{ span: 10, offset: 7 }}>

        <FormWrapper>
          <Spin spinning={loading} tip="Updating Settings..." size="large">
          <Form layout="vertical" name="settings" onFinish={onFinish} initialValues={setting}>

            <h1>Manage Settings</h1>

            <Form.Item
              label="Company Name"
              name="company"
              rules={[{ required: true, message: 'Please enter your company name' }]}
            >
              <Input placeholder="Company Name" />
            </Form.Item>

            <Form.Item
              name="website"
              label="Website URL"
              help="Please enter your company website url"
              rules={[{ required: true, message: 'Please enter a valid website url' }]}
            >
              <Input placeholder="https://" />
            </Form.Item>

            <Form.Item
              name="location"
              label="Location (Address)"
              help="Please enter your business location here"
              rules={[{ required: true, message: 'Please enter your business location' }]}
            >
              <Input placeholder="" />
            </Form.Item>

            <Divider />

            <Form.Item
              name="currency"
              label="Select Your Currency"
              rules={[{ required: true, message: 'Please select your currency' }]}
            >
              <Select placeholder="Your Currency">
                <Select.Option value="INR">INR</Select.Option>
                <Select.Option value="USD">USD</Select.Option>
                <Select.Option value="EUR">EUR</Select.Option>
                <Select.Option value="JPY">JPY</Select.Option>
                <Select.Option value="GBP">GBP</Select.Option>
                <Select.Option value="AUD">AUD</Select.Option>
                <Select.Option value="CAD">CAD</Select.Option>
                <Select.Option value="CNY">CNY</Select.Option>
                <Select.Option value="HKD">HKD</Select.Option>
              </Select>
            </Form.Item>

            <Form.Item style={{ marginBottom: 0 }}>
              <Button type="primary" htmlType="submit" style={{ height: 40 }} block>Update Settings</Button>
            </Form.Item>

          </Form>

        </Spin>
        </FormWrapper>

      </Col>
      </Row>


    </DefaultLayout>
  )

}


export default Settings;


const FormWrapper = styled.div`
  background: #fff;
  padding: 50px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid rgb(238, 238, 238);

  h1 {
    font-size: 32px;
    margin-bottom: 20px;
  }

`;
