import React, { Suspense, useState } from 'react';
import styled from 'styled-components';
import { DefaultLayout } from 'global/layouts';
import PageHeader from 'global/components/PageHeader';
import { Button, Tag, Avatar, Tabs, Modal, message } from 'antd';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { leadsState } from 'global/states';
import Loading from 'global/components/Loading';
import { DeleteOutlined, ExclamationCircleOutlined, UserOutlined, LinkOutlined, FacebookOutlined, TwitterOutlined, LinkedinOutlined, ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import Api from 'global/api';

import GeneralDetails from './tabs/General';
import LeadActivities from './tabs/Activities';
import LeadNote from './tabs/Note';






function confirmDelete(id, leads, setLeads, history) {
  Modal.confirm({
    title: 'Are you sure you want to delete this lead?',
    icon: <ExclamationCircleOutlined />,
    content: 'This is a non-reversible process, Once deleted you cannot recover this lead again.',
    okType: 'danger',
    okText: 'Yes, Delete',
    onOk() {
      return new Promise( async (resolve, reject) => {
        const res = await Api.deleteRecord('leads', id);
        if ( res ) {
          const allLeads = [...leads];
          const updatedLeads = allLeads.filter( lead => lead.id !== id );
          history.push('/leads/all');
          setLeads(updatedLeads);
          message.success(`Lead has been successfully deleted.`);
          resolve(res);
        } else {
          reject();
        }
      }).catch( () => console.log('Error !'));
    },
    onCancel() {},
  });
}


const ShowLead = (props) => {
  const [ leads, setLeads ] = useRecoilState(leadsState);
  const [ currentTab, setCurrentTab ] = useState('general')
  const { id } = props;
  const lead = leads.find( lead => lead.id === id );


  if ( ! lead ) {
    message.error('Lead not found');
    props.history.push('/leads/all');
    return null;
  }

  // get the previous and next lead ids
  const currentLeadIndex = leads.findIndex( lead => lead.id === id );
  let prevLeadId = null;
  let nextLeadId = null;
  if ( currentLeadIndex !== -1 ) {
    prevLeadId = leads[currentLeadIndex - 1] && leads[currentLeadIndex - 1].id;
    nextLeadId = leads[currentLeadIndex + 1] && leads[currentLeadIndex + 1].id;
  }

  const handleTabChange = (name) => {
    setCurrentTab(name);
  }


  return (
    <DefaultLayout
      header={
        <PageHeader
          title={lead.name}
          tags={<Tag style={{ textTransform: 'capitalize' }} color="#87d068">{ lead.status }</Tag>}
          onBack={ () => { props.history.push('/leads/all') } }
          buttons={[
            <Link key="update" to={`/leads/${lead.id}/update`}><Button type="primary">Update Lead</Button></Link>,
            <Button style={{ marginLeft: 10 }} key="delete" type="danger" icon={<DeleteOutlined />} onClick={ () => { confirmDelete( lead.id, leads, setLeads, props.history ) } } ghost>Delete Lead</Button>
          ]}
        />
      }
    >

      <Heading>
        <Avatar size={38} style={{ backgroundColor: '#87d068' }} icon={<UserOutlined />} />
        { lead.name }
      </Heading>

      <LinksContainer>
        { lead.website && <a href={lead.website} target="_blank" rel="noopener noreferrer"><LinkOutlined /></a> }
        { lead.social_facebook && <a href={lead.social_facebook} target="_blank" rel="noopener noreferrer"><FacebookOutlined /></a> }
        { lead.social_twitter && <a href={lead.social_twitter} target="_blank" rel="noopener noreferrer"><TwitterOutlined /></a> }
        { lead.social_linkedin && <a href={lead.social_linkedin} target="_blank" rel="noopener noreferrer"><LinkedinOutlined /></a> }
      </LinksContainer>


      <Tabs
        onChange={ handleTabChange }
        activeKey={ currentTab }
        type="card"
        tabBarExtraContent={[
          <Link to={ prevLeadId ? `/leads/${prevLeadId}` : '#' } key="prev"><Button ghost icon={<ArrowLeftOutlined />} type="primary" disabled={ ! prevLeadId }>Previous Lead</Button></Link>,
          <Link to={ nextLeadId ? `/leads/${nextLeadId}` : '#' } key="next"><Button ghost type="primary" style={{ marginLeft: 10 }} disabled={ ! nextLeadId }>Next Lead <ArrowRightOutlined /></Button></Link>
        ]}
      >

        <Tabs.TabPane tab="General Details" key="general">
          <GeneralDetails lead={lead} />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Activities" key="activities">
          <LeadActivities lead={lead} />
        </Tabs.TabPane>

        <Tabs.TabPane tab="Notes" key="notes">
          <LeadNote lead={lead} />
        </Tabs.TabPane>

      </Tabs>

    </DefaultLayout>
  )

}


const ShowLeadSuspense = (props) => {
  return (
    <Suspense fallback={<DefaultLayout><Loading message="Loading Lead..." /></DefaultLayout>}>
      <ShowLead id={props.match.params.id} history={props.history} />
    </Suspense>
  )
}



export default ShowLeadSuspense;



const Heading = styled.h1`
  display: flex;
  word-break: break-word;

  font-size: 32px;
  line-height: 110%;
  font-weight: 700;
  color: #000;
  align-items: center;

  span { margin-right: 10px; }
`;

const LinksContainer = styled.div`
  margin-left: 45px;
  margin-bottom: 20px;
  margin-top: 5px;

  a {
    font-size: 20px;
    margin-left: 10px;
    &:first-child { margin-left: 0; }
  }
`;

