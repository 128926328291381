import React from 'react';
import { Spin } from 'antd';
import styled from 'styled-components';

const Loading = (props) => {

  return (
    <LoadingWrapper>
      <Spin tip={ props.message || 'Loading...' }>
        { props.children }
      </Spin>
    </LoadingWrapper>
  )

}

export default Loading;


const LoadingWrapper = styled.div`
  text-align: center;
`;
