import React from 'react';
import styled from 'styled-components';



const Title = styled.div`
  font-size: 16px;
  color: #999;
  font-weight: 600;
  margin-bottom: 20px;
`;

const DataContainer = (props) => {

  return (
    <DataContainerWrapper>
      { props.title && <h1>{ props.title }</h1> }
      { props.text && <p>{ props.text }</p> }
      { props.extra && <div>{ props.extra }</div> }
    </DataContainerWrapper>
  )

}







export {
  Title,
  DataContainer
}




const DataContainerWrapper = styled.div`
  margin-top: 50px;
  width: 500px;
  padding: 40px 60px;
  margin: 0 auto;
  border-left: 5px solid #EEE;
  border-right: 5px solid #EEE;
  transition: border-color .5s ease-in;

  h1 {
    font-size: 32px;
    line-height: 110%;
    font-weight: 700;
    color: #000;
    margin-bottom: 20px;
  }

  p {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 20px;
  }

`;


