import React, { useState } from 'react';
import { useRecoilState } from 'recoil';
import { groupsState } from 'global/states';
import Modal from 'global/components/Modal';
import Api from 'global/api';
import { Form, Input, Button, message, Spin } from 'antd';



const CreateGroup = (props) => {
  const [ loading, setLoading ] = useState(false);
  const [ groups, setGroups ] = useRecoilState(groupsState);

  const handleSubmit = async (values) => {
    setLoading(true);
    const id = props.group.id || null;
    const res = await Api.saveGroup(values, id);

    if ( id ) {
      const allGroups = [...groups ];
      const index = allGroups.findIndex( group => group.id === id );
      allGroups[index] = { ...values, id: id }
      setGroups(allGroups);
      message.success('Group has been successfully updated.');
    } else {
      const allGroups = [...groups, { ...values, id: res.id }];
      setGroups(allGroups);
      message.success('New group has been successfully added.');
    }


    setLoading(false);
    props.hide();
  }

  return (
    <Modal
      title={ props.group.id ? 'Update Group' : 'Add New Group' }
      subtitle="Please enter details below to add/update group."
      icon="plus-square-o"
      visible={ props.show }
      onOk={ props.hide }
      onCancel={ props.hide }
      footer={null}
    >
      <Spin spinning={loading} tip={ props.group.id ? 'Updating group...' : 'Adding group...' } size="large">
      <div className="form--default">
      <Form name="create-group" colon={false} onFinish={handleSubmit} initialValues={props.group}>

        <Form.Item
          label="Group Name"
          name="name"
          rules={[
            { required: true, message: 'Please enter group name' },
          ]}
        >
          <Input placeholder="My Awesome Group" />
        </Form.Item>

        <Form.Item
          label="Group Description"
          name="description"
          rules={[
            { required: true, message: 'Please enter group description' },
          ]}
        >
          <Input.TextArea rows={4} placeholder="Group description" />
        </Form.Item>

        <Form.Item
          label="Group Source"
          name="source"
          rules={[
            { required: true, message: 'Please enter group source' },
          ]}
        >
          <Input placeholder="Where does this group get its leads from?" />
        </Form.Item>

        <div className="footer right-aligned">
          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit" block>{ props.group.id ? 'Update Group' : 'Add Group' }</Button>
          </Form.Item>
        </div>

      </Form>
      </div>
      </Spin>
    </Modal>
  )

}


export default CreateGroup;
