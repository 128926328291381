import React from 'react';
import { useRecoilValue } from 'recoil';
import { settingState, groupsState } from 'global/states';
import styled from 'styled-components';
import { Tag } from 'antd';
import { Link } from 'react-router-dom';
import { format, isBefore } from 'date-fns';


const GeneralDetails = (props) => {
  const { lead } = props;

  const settings = useRecoilValue(settingState);
  const groups = useRecoilValue(groupsState);
  const group = groups.find( group => lead.group === group.id );

  const duedate = new Date(lead.duedate.seconds * 1000);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: settings.currency || 'USD',
    minimumFractionDigits: 2,
  });


  return (
    <div>
      <KeyValWrapper>
        <div className="item"><div className="key">Email</div><div className="value"><a href={`mailto:${ lead.email }`}>{ lead.email }</a></div></div>
        { lead.phone && <div className="item"><div className="key">Phone</div><div className="value">{ lead.phone }</div></div> }
        { lead.location && <div className="item"><div className="key">Location</div><div className="value">{ lead.location }</div></div> }
        { lead.company && <div className="item"><div className="key">Company</div><div className="value">{ lead.company }</div></div> }
      </KeyValWrapper>

      { group && group.id ?
        (
          <KeyValWrapper>
            <div className="item"><div className="key">Group</div><div className="value"><Link to={`/groups/${ group.id }`}>{ group.name }</Link></div></div>
          </KeyValWrapper>
        ) : (
          <KeyValWrapper>
            <div className="item"><div className="key">Group</div><div className="value"><Tag color="red">Group Not Found</Tag></div></div>
          </KeyValWrapper>
        )
      }

      <KeyValWrapper>

        <div className="item">
          <div className="key">Due Date</div><div className="value">
            <Tag style={{ fontSize: 14 }} color={ isBefore( duedate, new Date() ) ? 'red' : 'blue' }>{ format(duedate, 'do MMM, yyyy') }</Tag>
          </div>
        </div>

        { lead.lead_cost &&
        <div className="item">
          <div className="key">Lead Cost</div><div className="value">{ formatter.format(lead.lead_cost) }</div>
        </div>
        }
        { lead.lead_worth &&
        <div className="item">
          <div className="key">Lead Worth</div><div className="value">{ formatter.format(lead.lead_worth) }</div>
        </div>
        }
        { (lead.lead_budget >= 1) &&
        <div className="item">
          <div className="key">Budget</div><div className="value">{ formatter.format(lead.lead_budget.toString()) }</div>
        </div>
        }
        { lead.status &&
        <div className="item">
          <div className="key">Status</div><div className="value" style={{ textTransform: 'capitalize' }}><Tag style={{ fontSize: 14 }} color="#87d068">{ lead.status }</Tag></div>
        </div>
        }

      </KeyValWrapper>

      <KeyValWrapper>

        <div className="item">
          <div className="key">Created At</div>
          <div className="value">{ format( new Date(lead.createdAt.seconds * 1000), 'do MMMM, yyyy @ p') }</div>
        </div>

        <div className="item">
          <div className="key">Updated At</div>
          { lead.updatedAt && <div className="value">{ format( new Date(lead.updatedAt.seconds * 1000), 'do MMMM, yyyy @ p') }</div> }
          { ! lead.updatedAt && <div className="value">Never</div> }
        </div>


      </KeyValWrapper>


    </div>
  )

}


export default GeneralDetails;


const KeyValWrapper = styled.div`
  margin-top: 30px;

  .item {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid hsla(0,0%,80%,.46);
    padding-bottom: 5px;
    margin-bottom: 5px;
    transition: all .3s ease;
  }
  .key {
    flex: 3;
    font-weight: bold;
    color: #000;
    padding-right: 20px;
  }

  .value {
    flex: 5;
    p {
      font-size: 16px;
      line-height: 16px;
      font-family: $ff_code;
      padding-bottom: 5px;
    }
  }

`;
