import React, { useState } from 'react';
import styled from 'styled-components';
import { DefaultLayout } from 'global/layouts';
import { Row, Col, Form, Input, Button, Divider, message, Spin } from 'antd';
import PageHeader from 'global/components/PageHeader';
import { useRecoilState } from 'recoil';
import { userState } from 'global/states';
import Api from 'global/api';
import { UnlockOutlined } from '@ant-design/icons';
import Modal from 'global/components/Modal';



const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16, offset: 1 }
};


const Profile = (props) => {
  const [ user, setUser ] = useRecoilState(userState);
  const [ loading, setLoading ] = useState(false);

  const [ passwordResetModal, setPasswordResetModal ] = useState(false);
  const [ updatingPassword, setUpdatingPassword ] = useState(false);



  const hidePasswordResetModal = () => { setPasswordResetModal(false); }


  const onFinish = async (values) => {
    setLoading(true);
    await Api.saveRecordWithId('users', user.uid, values);
    setUser({ ...user, ...values });

    setLoading(false);
    message.success(`Your profile has been successfully updated!`);
  }

  const handlePasswordUpdate = async (values) => {
    setUpdatingPassword(true);
    await Api.resetPassword(values.password);
    setUpdatingPassword(false);
  }



  return (
    <DefaultLayout
      header={
        <PageHeader
          title="Profile"
          subTitle="Manage profile"
          onBack={ () => { props.history.push('/') } }
          buttons={[
            <Button key="updatePassword" type="primary" icon={<UnlockOutlined />} onClick={ () => { setPasswordResetModal(true) } }>Update Password</Button>
          ]}
        />
      }
    >


      <Row>
      <Col xs={{ span: 24 }} md={{ span: 18, offset: 3 }} lg={{ span: 16, offset: 4 }} xl={{ span: 14, offset: 5 }} xxl={{ span: 10, offset: 7 }}>

        <HorizontalFormWrapper>
          <Spin spinning={loading} tip="Updating Profile..." size="large">
          <Form {...layout} colon={false} name="profile" onFinish={onFinish} initialValues={user}>

            <Form.Item
              label="UID"
              name="uid"
            >
              <Input disabled />
            </Form.Item>

            <Form.Item
              label="Full Name"
              name="name"
              rules={[{ required: true, message: 'Please enter your full name!' }]}
            >
              <Input placeholder="Full Name" />
            </Form.Item>

            <Form.Item
              name="email"
              label="Email Address"
            >
              <Input disabled />
            </Form.Item>

            <Divider />

            <Form.Item
              name="phone"
              label="Contact Number"
              help="Please enter your contact number with country code"
              rules={[{ required: true, message: 'Please enter your contact number!' }]}
            >
              <Input placeholder="+91 " />
            </Form.Item>

            <Divider />

            <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 7 }} style={{ marginBottom: 0 }}>
              <Button type="primary" htmlType="submit" block style={{ height: 40 }}>Update Profile</Button>
            </Form.Item>

          </Form>

        </Spin>
        </HorizontalFormWrapper>

      </Col>
      </Row>


      {/* update password modal popup */}
      { passwordResetModal &&
      <Modal
        title="Update Your Password"
        subtitle="Please enter new password below to update it."
        icon="plus-square-o"
        visible={ passwordResetModal }
        onOk={ hidePasswordResetModal }
        onCancel={ hidePasswordResetModal }
        footer={null}
      >
        <Spin spinning={updatingPassword} tip="Updating Password..." size="large">
        <Form layout="vertical" name="update-password" onFinish={handlePasswordUpdate}>

          <Form.Item
            label="Enter New Password"
            name="password"
            extra="Please enter your new password here!"
            rules={[
              { required: true, message: 'Please enter your password!' },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Please confirm your new password!' },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                  }
                  return Promise.reject('The two passwords that you entered do not match!');
                }
              })
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit" style={{ height: 40 }} block>Update Password</Button>
          </Form.Item>

        </Form>
        </Spin>
      </Modal>
      }


    </DefaultLayout>
  )

}


export default Profile;



const HorizontalFormWrapper = styled.div`
  background: #fff;
  padding: 50px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid rgb(238, 238, 238);

  form label {
    font-weight: 600;
  }

  @media (max-width: 900px) {
    .ant-form-item {
      flex-direction: column;

      div[class^="ant-col"] {
        max-width: 100%;
        margin: 0;
      }
    }
    .ant-form-item-label { display: flex; }
    .ant-form-item-control { display: flex; }
  }
`;
