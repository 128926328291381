import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import 'global/styles/style.css';

import NotFound from 'pages/common/NotFound';
import Dashboard from 'pages/common/Dashboard';

import {
  Login as AuthLogin,
  Logout as AuthLogout,
  ResetPassword as AuthResetPassword
} from 'pages/auth';

import SettingsPage from 'pages/common/Settings';
import SupportPage from 'pages/common/Support';
import ProfilePage from 'pages/common/Profile';
import CalendarPage from 'pages/common/Calendar';
import ImportExportPage from 'pages/common/ImportExport';

import GroupsAllPage from 'pages/groups/All';
import GroupsShowPage from 'pages/groups/Show';
import GroupsLeadsPage from 'pages/groups/Leads';

import LeadsAllPage from 'pages/leads/All';
import LeadsCreatePage from 'pages/leads/Create';
import LeadsUpdatePage from 'pages/leads/Update';
import LeadsShowPage from 'pages/leads/Show';


const App = (props) => {

  return (
    <Router>
      <Switch>
        <Route path="/auth/login" component={ AuthLogin } />
        <Route path="/auth/reset-password" component={ AuthResetPassword } />
        <Route path="/auth/logout" component={ AuthLogout } />

        <Route exact path="/" component={ Dashboard } />
        <Route exact path="/settings" component={ SettingsPage } />
        <Route exact path="/account/support" component={ SupportPage } />
        <Route exact path="/account/profile" component={ ProfilePage } />

        <Route exact path="/groups" component={ GroupsAllPage } />
        <Route exact path="/groups/:id" component={ GroupsShowPage } />
        <Route exact path="/groups/:id/leads" component={ GroupsLeadsPage } />

        <Route exact path="/leads/all" component={ LeadsAllPage } />
        <Route exact path="/leads/create" component={ LeadsCreatePage } />
        <Route exact path="/leads/:id" component={ LeadsShowPage } />
        <Route exact path="/leads/:id/update" component={ LeadsUpdatePage } />

        <Route exact path="/calendar" component={ CalendarPage } />
        <Route exact path="/import-export" component={ ImportExportPage } />

        <Route component={ NotFound } />
      </Switch>
      { (! props.user || props.user === null) && <Redirect from="/" to="/auth/login" /> }
    </Router>
  )
}

export default App;


