import React from 'react';
import styled from 'styled-components';
import { DefaultLayout } from 'global/layouts';
import { TeamOutlined, CalendarOutlined, SettingOutlined } from '@ant-design/icons';
import PageHeader from 'global/components/PageHeader';
import { Button, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import { UserAddOutlined, PartitionOutlined, UserOutlined, ImportOutlined } from '@ant-design/icons';
import { useRecoilValue } from 'recoil';
import { userState } from 'global/states';


const apps = [
  {
    url: '/leads/all',
    icon: <TeamOutlined />,
    label: 'Leads',
  },
  {
    url: '/groups',
    icon: <PartitionOutlined />,
    label: 'Groups',
  },
  {
    url: '/calendar',
    icon: <CalendarOutlined />,
    label: 'Calendar',
  },
  {
    url: '/settings',
    icon: <SettingOutlined />,
    label: 'Settings',
  },
  {
    url: '/import-export',
    icon: <ImportOutlined />,
    label: 'Import/Export',
  },
  {
    url: '/account/profile',
    icon: <UserOutlined />,
    label: 'Manage Profile',
  },


];



const Home = (props) => {
  const user = useRecoilValue(userState);
  let userName = 'Administrator';
  if ( user.name ) {
    const names = user.name.split(' ');
    if ( names.length > 0 ) { userName = names[0]; }
  }

  return (
    <DefaultLayout
      header={
        <PageHeader
          title="Dashboard"
          subTitle="Welcome to Lead Manager Dashboard"
          buttons={[
            <Link key={'add-user'} to={'/leads/create'}><Button type="primary" ghost icon={<UserAddOutlined />}>Add New Lead</Button></Link>,
            <Link key={'add-group'} to={{ pathname: `/groups`, state: { create: true } }}><Button type="primary" icon={<PartitionOutlined />}>Add New Group</Button></Link>
          ]}
        />
      }
    >

      <Row style={{ marginBottom: 40 }}>
        <Col xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} lg={{ span: 12, offset: 6 }} xxl={{ span: 10, offset: 7 }}>
          <Heading>Hi { userName }, Welcome To Lead Manager Application</Heading>
          <SubHeading>Manage your leads, leads activities, notes, groups, calendar and more very easily.</SubHeading>
        </Col>

        <Col style={{ marginTop: 40 }} xs={{ span: 20, offset: 2 }} md={{ span: 20, offset: 2 }} lg={{ span: 14, offset: 5 }} xxl={{ span: 10, offset: 7 }}>
          <Row gutter={[20,20]}>
            { apps.map( (app, index) => {
              return (
                <Col key={index} xs={12} md={8} lg={8} xxl={8}>
                <Application to={app.url}>
                  <IconContainer>{ app.icon }</IconContainer>
                  <Title className="title">{ app.label }</Title>
                </Application>
                </Col>
              )
            }) }
          </Row>
        </Col>
      </Row>


    </DefaultLayout>
  )

}


export default Home;



const Application = styled(Link)`
  display: block;
  background: rgba(0, 0, 0, 0.5);
  padding: 30px;
  min-height: 120px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    box-shadow: 0 0 0 #ebffd4, 0 0 0 #d0ff9b, 0 10px 10px #b6f7ff;
  }
`;

const IconContainer = styled.div`
  text-align: center;
  font-size: 40px;
  color: #fff;
`;

const Title = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 15px;
  line-height: 100%;
  color: #FFF;
  font-weight: 600;
`;

const Heading = styled.h1`
  font-size: 46px;
  line-height: 120%;
  color: #212529;
`;

const SubHeading = styled.h1`
  font-size: 20px;
  line-height: 120%;
  color: #6c757d;
  margin-top: 20px;
`;


/*



const AppList = styled.div`
  max-width: 800px;
  margin: 0 auto;
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;

  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
  }

`;

      <Col xs={{ span: 20, offset: 2 }} md={{ span: 18, offset: 3 }} lg={{ span: 16, offset: 4 }} xxl={{ span: 12, offset: 6 }}>
        <Row gutter={[20,20]}>
          { apps.map( (app, index) => {
            return (
              <Col key={index} xs={12} md={12} lg={12} xxl={6}>
              <Applicationx to={app.url}>
                <IconContainer>{ app.icon }</IconContainer>
                <Title className="title">{ app.label }</Title>
              </Applicationx>
              </Col>
            )
          }) }
        </Row>
      </Col>


      <AppList>
        { apps.map( (app, index) => {
          return (
            <Application key={index} to={app.url}>
              <IconContainer>{ app.icon }</IconContainer>
              <Title className="title">{ app.label }</Title>
            </Application>
          )
        }) }
      </AppList>

*/
