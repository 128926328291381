import React, { Fragment, useEffect } from 'react';
import styled from 'styled-components';
import Header from 'global/components/Header';
import Sidebar from 'global/components/Sidebar';
import { useRecoilState } from 'recoil';
import { sidebarVisible } from 'global/states';
import { useMediaQuery } from 'react-responsive';


const DefaultLayout = (props) => {

  const [sidebar, setSidebar] = useRecoilState(sidebarVisible);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

  const toggleSidebar = () => {
    setSidebar(!sidebar);
    localStorage.setItem('sidebar', !sidebar);
    localStorage.setItem('sidebarUt', true);
  }

  useEffect( () => {
    const userTouched = localStorage.getItem('sidebarUt');
    if ( userTouched ) { return; }

    if ( isTabletOrMobile ) {
      setSidebar(false);
      localStorage.setItem('sidebar', false);
    } else {
      setSidebar(true);
      localStorage.setItem('sidebar', true);
    }
  }, [isTabletOrMobile, setSidebar]);


  return (
    <Fragment>
      <Header sidebar={sidebar} toggleSidebar={toggleSidebar} />
      <Sidebar visible={sidebar} />

      <Content className={ sidebar ? 'has-sidebar' : '' }>
        { props.header && props.header }
        { props.extra && props.extra }
        <Inner>
          { props.children }
        </Inner>
      </Content>

    </Fragment>
  )

}


DefaultLayout.whyDidYouRender = true

export default DefaultLayout;


const Content = styled.div`
  padding-top: 50px;
  transition: all .3s ease;

  &.has-sidebar {
    margin-left: 260px;;
    width: calc( 100% - 260px; );
  }
`;

const Inner = styled.div`
  width: 100%;
  padding: 50px;
  margin: 0 auto;
`;
