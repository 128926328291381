import React from 'react';
import { DefaultLayout } from 'global/layouts';
import { DataContainer } from 'global/components/UI';
import { Button } from 'antd';
import { Link } from 'react-router-dom';



const PageNotFound = (props) => {

  return (
    <DefaultLayout>

      <DataContainer
        title="Page Not Found!"
        text="The page you're looking for doesn't exist or you don't have permissions to access it."
        extra={ <Link to="/"><Button type="primary">&larr; Back to Home</Button></Link> }
      />

    </DefaultLayout>
  )

}


export default PageNotFound;
