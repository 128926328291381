import React, { useState } from 'react';
import styled from 'styled-components';
import { AuthLayout } from 'global/layouts';
import { Link } from 'react-router-dom';
import { Row, Col, Form, Input, Button, Spin, message } from 'antd';
import { MailOutlined } from '@ant-design/icons';
import Api from 'global/api';



const ResetPasswordPage = (props) => {

  const [ loading, setLoading ] = useState(false);

  const handleSubmit = (values) => {
    setLoading(true);
    Api.auth.sendPasswordResetEmail(values.email).then( () => {
      setLoading(false);
      message.success(`We have sent you a Password reset email.`);
    }).catch( (err) => {
      setLoading(false);
      message.error(`Error Occurred: ${ err.message }`);
    });
  }

  return (
    <AuthLayout>

      <Row>
      <Col xs={{ span: 24 }} md={{ span: 16, offset: 4 }} lg={{ span: 12, offset: 6 }} xl={{ span: 10, offset: 7 }} xxl={{ span: 6, offset: 9 }}>
        <SignupContainer>
        <Spin tip="Processing, please wait..." spinning={ loading }>

          <h2>Enter your email address to Reset Password</h2>

          <Form
            layout="vertical"
            name="normal_login"
            onFinish={ handleSubmit }
          >

            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                { required: true, message: 'Please enter your email address!' },
                { type: 'email', message: 'Please enter a valid email address!' }
              ]}
            >
              <Input prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder="Email Address" />
            </Form.Item>

            <Form.Item>
              <ButtonContainer>
                <Button type="primary" htmlType="submit">Reset Password</Button>
                <Link to="/auth/login"><Button type="primary" ghost>Back to Login</Button></Link>
              </ButtonContainer>
            </Form.Item>


          </Form>

        </Spin>
        </SignupContainer>

      </Col>
      </Row>

    </AuthLayout>
  )

}


export default ResetPasswordPage;



const SignupContainer = styled.div`
  margin: 0 auto;
  padding: 40px;
  box-shadow: 0 0 0 rgb(235, 255, 212), 0 0 0 rgb(208, 255, 155), 0 20px 20px #b6f7ff;

  @media (max-width: 420px) {
    padding: 30px;
  }

  h2 {
    font-weight: 700;
    margin-bottom: 30px;
    font-size: 28px;
    line-height: 100%;
  }

  .ant-form-item:last-child {
    margin-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
`;
