import React from 'react';
import ReactDOM from 'react-dom';
import { RecoilRoot } from 'recoil';
import Api from 'global/api';
import App from './App';
// import whyDidYouRender from '@welldone-software/why-did-you-render';

// if (process.env.NODE_ENV === 'development') {}
// whyDidYouRender(React, {
//   trackAllPureComponents: true,
// });



Api.auth.onAuthStateChanged( async (user) => {
  let userData = null;

  if ( user && user.uid ) {
    const data = await Api.fetchUserData( user.uid );
    userData = {
      ...data,
      uid: user.uid,
      email: user.email
    };
    localStorage.setItem('userData', JSON.stringify(userData));

    const settings = await Api.fetchSettings();
    localStorage.setItem('settings', JSON.stringify(settings));
  }

  return ReactDOM.render(
    <React.StrictMode>
      <RecoilRoot >
        <App user={userData} />
      </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
  );
});
