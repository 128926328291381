import React, { Suspense, Fragment } from 'react';
import { DefaultLayout } from 'global/layouts';
import PageHeader from 'global/components/PageHeader';

import { DataContainer } from 'global/components/UI';
import { Button, Tag, Modal, message } from 'antd';
import { Link } from 'react-router-dom';
import { useRecoilState } from 'recoil';
import { groupsState } from 'global/states';
import Loading from 'global/components/Loading';
import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import Api from 'global/api';


const GroupData = (props) => {
  const { group } = props;

  return (
    <DataContainer
      title={ group.name }
      text={ group.description }
      extra={
        <Fragment>
          <div><Tag style={{ fontSize: 14, marginBottom: 20 }} color="green">Source</Tag> { group.source }</div>
          <Link to={`/groups/${ group.id }/leads`}><Button type="primary" ghost>View All Leads</Button></Link>
        </Fragment>
      }
    />
  )
}


const ShowGroup = (props) => {
  const [ groups, setGroups ] = useRecoilState(groupsState);

  const { id } = props;
  const group = groups.find( group => group.id === id );

  if ( ! group ) {
    message.error('Group not found');
    props.history.push('/groups');
    return null;
  }

  const confirmDelete = () => {
    Modal.confirm({
      title: 'Are you sure you want to delete this group?',
      icon: <ExclamationCircleOutlined />,
      content: 'This is a non-reversible process, Once deleted you cannot recover this group again.',
      okType: 'danger',
      okText: 'Yes, Delete',
      onOk() {
        return new Promise( async (resolve, reject) => {
          const res = await Api.deleteRecord('groups', id);
          if ( res ) {
            const allGroups = [...groups];
            const updatedGroups = allGroups.filter( group => group.id !== id );
            message.success(`Group has been successfully deleted.`);
            props.history.push('/groups')
            setGroups(updatedGroups);
            resolve(res);
          } else {
            reject();
          }
        }).catch( () => console.log('Error !'));
      },
      onCancel() {},
    });
  }


  return (
    <DefaultLayout
      header={
        <PageHeader
          title={ group.name }
          onBack={ () => { props.history.push('/groups') } }
          buttons={[
            <Link key="addLead" to={{ pathname: `/leads/create`, state: { groupId: group.id } }}><Button type="primary" icon={<PlusOutlined />}>Add New Lead</Button></Link>,
            <Button key="deleteGroup" type="danger" icon={<DeleteOutlined />} onClick={ confirmDelete } style={{ marginLeft: 10 }} ghost>Delete This Group</Button>
          ]}
          style={{
            borderBottom: '1px solid #9cd3ff',
            background: '#d4ecff',
            padding: '4px 22px',
          }}
        />
      }
    >

      <GroupData group={group} />

    </DefaultLayout>
  )

}


const ShowGroupSuspense = (props) => {
  return (
    <Suspense fallback={<DefaultLayout><Loading message="Loading Group..." /></DefaultLayout>}>
      <ShowGroup id={props.match.params.id} history={props.history} />
    </Suspense>
  )
}

export default ShowGroupSuspense;


/*
<Link key="addLead" to="/leads/create"><Button type="primary" icon={<PlusOutlined />}>Add New Lead</Button></Link>
*/
