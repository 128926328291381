import React from 'react';
import { Modal } from 'antd';
import { LaptopOutlined } from '@ant-design/icons';
import styled from 'styled-components';


const ModalHeader = (props) => {

  return (
    <header>
      <LaptopOutlined />
      <div className="info">
        { props.title && <div className="title">{ props.title }</div> }
        { props.subtitle &&
        <div className="subtitle">{ props.subtitle }</div>
        }
      </div>
    </header>
  );

}

const ModalComponent = props => {


  return (
    <StyledModal
      visible={ props.visible }
      onOk={ props.onOk }
      onCancel={ props.onCancel }
      maskClosable={ false }
      maskStyle={{
        opacity: '0.6'
      }}
      footer={ props.footer }
    >
      <div>

        <ModalHeader
          title={ props.title }
          icon={ props.icon }
          subtitle={ props.subtitle }
        />

        <div className="modal__container">
          <div className="content">
            { props.children }
          </div>
        </div>

      </div>
    </StyledModal>
  )
}


export default ModalComponent;



const StyledModal = styled(Modal)`
  &.ant-modal {
    top: 48px;
    width: 70% !important;
    max-width: 700px;
    padding-bottom: 0;
    border-radius: 4px;
  }

  .ant-modal-content {
    background: #EDEFF0;
    border-radius: 4px;
    box-shadow: none;
    .ant-modal-footer {
      padding: 20px 46px;
      border-top: 1px solid #CCC;
    }
    .ant-modal-header {
      border: none;
      background: transparent;
    }
    .ant-modal-body {
      padding: 20px;
      padding-bottom: 50px;
      padding-top: 30px;
    }
  }

  .footer-buttons {
    margin-bottom: 0;
  }
  .ant-steps-item-process > .ant-steps-item-content > .ant-steps-item-title:after {
    background-color: #CCC;
  }


  header {
    display: flex;

    .anticon {
      margin-right: 10px;
      font-size: 20px;
      line-height: 28px;
      margin-top: 4px;
    }

    .info {
      flex: 1;
    }
    .title {
      font-size: 20px;
      line-height: 20px;
      color: #000;
      font-weight: bold;
      padding: 4px 6px;
      border-radius: 3px;
      margin-right: 30px;
      transition: all .3s ease;

      span {
        font-weight: bold;
        color: #000;
      }
    }

    .subtitle {
      margin-top: 2px;
      margin-left: 6px;
      font-size: 16px;
      line-height: 16px;
      color: #8c8c8c;
      span.underline {
        text-decoration: underline;
        cursor: pointer;
      }
    }

  }


  .modal__container {
    margin-left: 36px;
    margin-right: 36px;
    margin-top: 20px;
    overflow: visible;
    display: flex;

    .content {
      min-height: 200px;
      flex: 1;
      width: 100%;
    }
  }

  .ant-btn-primary {
    height: 40px;
  }


.form--default {
  margin-top: 30px;

  label, input, textarea {
    cursor: pointer;
    color: #000;
  }

  .ant-form-item {
    display: flex;
    border-bottom: 1px solid hsla(0,0%,80%,.46);
    margin: 10px 0;
    padding-bottom: 10px;
    transition: all .3s ease;

    &:last-child { border-bottom: 0; }

    &.footer-buttons {
      border: none;
      &:hover { background: transparent; }
    }

    .ant-form-item-label, .key { flex: 2; padding-right: 20px; }
    .ant-form-item-label label {
      position: relative;
      line-height: 100%;
      display: inherit;
      white-space: normal;
      text-align: left;
      flex: 2;
      font-weight: bold;
      color: #000;
      padding: 5px 0;
    }
    .ant-form-item-control, .value {
      flex: 5;
      p {
        font-size: 16px;
        line-height: 16px;
        font-family: Monaco,Consolas,Andale Mono,DejaVu Sans Mono,monospace;
        padding-bottom: 5px;
      }
      .ant-form-explain { position: relative !important; }
    }
  }


  .footer {
    display: flex;

    &.right-aligned::before {
      content: '';
      flex: 2;
      padding-right: 20px;
    }
    .ant-form-item {
      flex: 5;
      padding: 0;
      margin: 0;
    }

  }



}



`;

