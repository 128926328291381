import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Modal, List, Avatar, Button, message } from 'antd';
import { Link } from 'react-router-dom';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import Api from 'global/api';
import { useRecoilState } from 'recoil';
import { groupsState } from 'global/states';
import SaveGroup from './Save';


function confirmDelete(id, groups, setGroups) {
  Modal.confirm({
    title: 'Are you sure you want to delete this group?',
    icon: <ExclamationCircleOutlined />,
    content: 'This is a non-reversible process, Once deleted you cannot recover this group again.',
    okType: 'danger',
    okText: 'Yes, Delete',
    onOk() {
      return new Promise( async (resolve, reject) => {
        const res = await Api.deleteRecord('groups', id);
        if ( res ) {
          const allGroups = [...groups];
          const updatedGroups = allGroups.filter( group => group.id !== id );
          setGroups(updatedGroups);
          resolve(res);
          message.success(`Group has been successfully deleted.`);
        } else {
          reject();
        }
      }).catch( () => console.log('Error !'));
    },
    onCancel() {},
  });
}



const GroupList = (props) => {
  const [ groups, setGroups ] = useRecoilState(groupsState);

  const [ showUpdateGroupModal, setShowUpdateGroupModal ] = useState(false);
  const [ selectedGroup, setSelectedGroup ] = useState({});
  const hideUpdateGroupModal = () => { setShowUpdateGroupModal(false); }

  const updateGroup = (id) => {
    const data = groups.find( item => item.id === id );
    setSelectedGroup(data);
    setShowUpdateGroupModal(true);
  }


  const renderItem = (item) => (
    <List.Item
      className="item"
      actions={[
        <Button key="edit" type="primary" size="small" ghost onClick={ () => { updateGroup(item.id) } }>Edit</Button>,
        <Button key="delete" type="danger" onClick={ () => { confirmDelete(item.id, groups, setGroups) } } size="small" ghost>Delete</Button>
      ]}
    >
      <List.Item.Meta
        avatar={<Avatar style={{ backgroundColor: '#87d068' }}>{ item.name.charAt(0) }</Avatar>}
        title={ <Link to={`/groups/${item.id}`}>{ item.name }</Link> }
        description={ item.description }
      />
    </List.Item>
  )

  return (
    <Fragment>
      <ListContainer>
        <List
          itemLayout={ 'horizontal' }
          dataSource={ props.data }
          renderItem={ renderItem }
        />
      </ListContainer>

      { showUpdateGroupModal &&
        <SaveGroup show={showUpdateGroupModal} hide={hideUpdateGroupModal} group={selectedGroup} />
      }

    </Fragment>
  )

}

export default GroupList;


const ListContainer = styled.div`
  .item {
    background: #FFF;
    padding: 20px;
    margin-bottom: 10px;
    border-radius: 4px;
    transition: all .3s ease;

    &:hover {
      background: #fffce0;
    }
    .description {
      margin-top: 10px;
      p {
        font-size: 14px;
        line-height: 120%;
        margin: 0;
        span { font-weight: bold; color: #444; }
      }
    }
  }

  .ant-list-item-meta-title {
    font-size: 16px;
    font-weight: bold;
    line-height: 100%;
  }
  .ant-list-item-meta-description { line-height: 110%; margin-top: 5px; }

`;


