import React from 'react';
import { DefaultLayout } from 'global/layouts';
import { DataContainer } from 'global/components/UI';
import { Tag } from 'antd';


const Support = (props) => {
  return (
    <DefaultLayout>

      <DataContainer
        title="Need Help With Anything? We're Here To Help!"
        text="Just send us an email with your query/feedback/issue and we will try to resolve it as soon as possible."
        extra={ <a href="mailto:support@leadmanager.app"><Tag style={{ fontSize: 16, padding: '5px 10px' }} color="blue">support@leadmanager.app</Tag></a> }
      />

    </DefaultLayout>
  )
}


export default Support;
