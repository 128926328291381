import React, { Suspense, useState } from 'react';
import styled from 'styled-components';
import { DefaultLayout } from 'global/layouts';
import { Steps, Row, Col, Form, Input, InputNumber, Select, Button, Divider, message, Spin } from 'antd';
import DatePicker from 'global/components/UI/DatePicker';

import PageHeader from 'global/components/PageHeader';
import { useRecoilState, useRecoilValue } from 'recoil';
import { groupsState, leadsState } from 'global/states';
import Loading from 'global/components/Loading';
import Api from 'global/api';
import { toDate } from 'date-fns';


const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 16, offset: 1 }
};

const leadStatus = [
  { value: 'new', title: 'New' },
  { value: 'connecting', title: 'Connecting' },
  { value: 'connected', title: 'Connected' },
  { value: 'open', title: 'Open' },
  { value: 'qualified', title: 'Qualified' },
  { value: 'completed', title: 'Completed' },
  { value: 'disqualified', title: 'Disqualified' },
];



const GeneralDetails = (props) => {
  const groups = useRecoilValue(groupsState);

  return (
    <FormWrapper>
      <Form {...layout} colon={false} name="update-lead" onFinish={props.onFinish} initialValues={props.data}>

        <Form.Item
          name="group"
          label="Select Group"
          rules={[
            { required: true, message: 'Please select a group this lead is associated with' },
          ]}
        >
          <Select placeholder="Select Group" allowClear>
            { groups.map( group => <Select.Option key={group.id} value={group.id}>{group.name}</Select.Option> )}
          </Select>
        </Form.Item>

        <Form.Item
          name="name"
          label="Full Name"
          rules={[
            { required: true, message: 'Please enter your full name' },
          ]}
        >
          <Input placeholder="Full Name" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email Address"
          rules={[
            { required: true, message: 'Please enter your email address!' },
            { type: 'email', message: 'Please enter a valid email address!' }
          ]}
        >
          <Input placeholder="Email Address" />
        </Form.Item>

        <Form.Item
          name="phone"
          label="Contact Number"
        >
          <Input placeholder="Contact Number" />
        </Form.Item>

        <Form.Item name="gender" label="Gender">
          <Select placeholder="Select Gender">
            <Select.Option value="male">Male</Select.Option>
            <Select.Option value="female">Female</Select.Option>
            <Select.Option value="unspecified">Unspecified</Select.Option>
          </Select>
        </Form.Item>

        <Divider />

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 7 }} style={{ marginBottom: 0 }}>
          <Button type="primary" htmlType="submit" style={{ height: 40 }}>Continue</Button>
        </Form.Item>

      </Form>

    </FormWrapper>
  )

}




const BusinessDetails = (props) => {

  return (
    <FormWrapper>
      <Form {...layout} colon={false} name="update-lead" onFinish={props.onFinish} initialValues={props.data}>

        <Form.Item
          name="company"
          label="Company Name"
        >
          <Input placeholder="Company Name" />
        </Form.Item>

        <Form.Item
          name="location"
          label="Location"
        >
          <Input placeholder="Location" />
        </Form.Item>

        <Form.Item
          name="website"
          label="Website URL"
        >
          <Input placeholder="https://" />
        </Form.Item>

        <Form.Item
          name="social_facebook"
          label="Facebook"
        >
          <Input placeholder="Facebook" />
        </Form.Item>

        <Form.Item
          name="social_twitter"
          label="Twitter"
        >
          <Input placeholder="Twitter" />
        </Form.Item>

        <Form.Item
          name="social_linkedin"
          label="LinkedIn"
        >
          <Input placeholder="LinkedIn" />
        </Form.Item>

        <Divider />

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 7 }} style={{ marginBottom: 0 }}>
          <Button type="primary" htmlType="submit" style={{ height: 40 }}>Continue</Button>
          <Button type="primary" onClick={ props.onPrevious } style={{ height: 40, marginLeft: 20 }} ghost>Previous Step</Button>
        </Form.Item>

      </Form>

    </FormWrapper>
  )

}


const AdditionalDetails = (props) => {

  return (
    <FormWrapper>
      <Spin spinning={props.loading} tip="Updating Lead..." size="large">
      <Form {...layout} colon={false} name="update-lead" onFinish={props.onFinish} initialValues={props.data}>

        <Form.Item
          name="status"
          label="Lead Status"
          rules={[
            { required: true, message: 'Please select current status of the lead' },
          ]}
        >
          <Select placeholder="Select Lead Status" showSearch allowClear>
            { leadStatus.map( status => <Select.Option key={status.value} value={status.value}>{status.title}</Select.Option> )}
          </Select>
        </Form.Item>

        <Form.Item
          name="duedate"
          label="Due Date"
          rules={[
            { required: true, message: 'Please select a due date for this lead' },
          ]}
        >
          <DatePicker placeholder="Due Date" style={{ width: 260 }} />
        </Form.Item>


        <Form.Item
          name="lead_cost"
          label="Acquisition Cost"
          help="How much did it cost to acquire this lead?"
          rules={[
            { required: true, message: 'Please enter lead Acquisition cost' },
          ]}
        >
          <InputNumber placeholder="Acquisition Cost" style={{ width: 260 }} />
        </Form.Item>

        <Form.Item
          name="lead_worth"
          label="Estimated Worth"
          help="How much is this lead worth?"
          rules={[
            { required: true, message: 'Please enter estimated lead worth?' },
          ]}
        >
          <InputNumber placeholder="Estimated Worth" style={{ width: 260 }} />
        </Form.Item>

        <Form.Item
          name="lead_budget"
          label="Lead Budget"
          help="What is their budget for the products/services required?"
        >
          <InputNumber placeholder="Lead Budget" style={{ width: 260 }} />
        </Form.Item>

        <Divider />

        <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 7 }} style={{ marginBottom: 0 }}>
          <Button type="primary" htmlType="submit" style={{ height: 40 }}>Update Lead</Button>
          <Button type="primary" onClick={ props.onPrevious } style={{ height: 40, marginLeft: 20 }} ghost>Previous Step</Button>
        </Form.Item>

      </Form>

    </Spin>
    </FormWrapper>
  )

}








const UpdateLeadForm = (props) => {

  const [ loading, setLoading ] = useState(false);
  const [ step, setStep ] = useState(0);
  const [ data, setData ] = useState({});
  const [ leads, setLeads ] = useRecoilState(leadsState);

  const lead = leads.find( lead => lead.id === props.leadId );
  const initialValues = {
    ...lead,
    duedate: new Date(lead.duedate.seconds * 1000),
    ...data,
  };


  const onFinish = async (values) => {
    let output = {...data, ...values};
    Object.keys(output).forEach(function (key) {
      if ( typeof output[key] === 'undefined' ){
        delete output[key];
      } else if ( key === 'duedate' ) {
        output[key] = toDate(output[key]);
      }
    });

    setLoading(true);

    await Api.saveLead(output, lead.id);

    output.duedate = { seconds: Math.floor(output.duedate / 1000) }
    output.updatedAt = { seconds: Math.floor(new Date() / 1000) }

    const allLeads = [...leads];
    const index = allLeads.findIndex( _lead => _lead.id === lead.id );
    allLeads[index] = { ...output, id: lead.id, createdAt: lead.createdAt }
    setLeads(allLeads);
    message.success('Lead has been successfully updated.');

    setLoading(false);
    props.history.push(`/leads/${lead.id}`);
  }

  const nextStep = (values) => {
    const output = {...data, ...values};
    setData(output);
    setStep(step+1);
  }
  const prevStep = (values) => {
    setStep(step-1);
  }


  return (
    <DefaultLayout
      header={
        <PageHeader
          title={ lead.name }
          subTitle="Update Lead"
          onBack={ () => { props.history.push(`/leads/${lead.id}`) } }
        />
      }
    >

      <Row>
      <Col xs={{ span: 24 }} md={{ span: 18, offset: 3 }} lg={{ span: 16, offset: 4 }} xl={{ span: 14, offset: 5 }} xxl={{ span: 12, offset: 6 }}>

        <Steps current={step} style={{ marginBottom: 30 }}>
          <Steps.Step title="General Detals" description="Enter general details" />
          <Steps.Step title="Business Details" description="Enter business details" />
          <Steps.Step title="Additional Details" description="Enter additional details" />
        </Steps>

        { step === 0 && <GeneralDetails data={initialValues} loading={false} onFinish={nextStep} /> }
        { step === 1 && <BusinessDetails data={initialValues} loading={false} onFinish={nextStep} onPrevious={prevStep} /> }
        { step === 2 && <AdditionalDetails data={initialValues} loading={loading} onFinish={onFinish} onPrevious={prevStep} /> }

      </Col>
      </Row>

    </DefaultLayout>
  )

}


const UpdateLead = (props) => {
  return (
    <Suspense fallback={<DefaultLayout><Loading message="Loading..." /></DefaultLayout>}>
      <UpdateLeadForm history={props.history} leadId={props.match.params.id} />
    </Suspense>
  )
}


export default UpdateLead;


const FormWrapper = styled.div`
  background: #fff;
  padding: 50px;
  margin: 0 auto;
  border-radius: 4px;
  border: 1px solid rgb(238, 238, 238);

  form label {
    font-weight: 600;
  }

  @media (max-width: 900px) {
    .ant-form-item {
      flex-direction: column;

      div[class^="ant-col"] {
        max-width: 100%;
        margin: 0;
      }
    }
    .ant-form-item-label { display: flex; }
    .ant-form-item-control { display: flex; }
  }
`;

