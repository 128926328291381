import React, { Fragment, useState } from 'react';
import styled from 'styled-components';
import { Form, Input, Spin, Button, message } from 'antd';
import Api from 'global/api';
import { useRecoilState } from 'recoil';
import { leadsState } from 'global/states';
import ReactMarkdown from 'react-markdown';



const Note = (props) => {
  const [ leads, setLeads ] = useRecoilState(leadsState);
  const [ addNote, setAddNote ] = useState(false);
  const [ loading, setLoading ] = useState(false);
  const { note } = props.lead;

  const handleSubmit = async (values) => {
    const id = props.lead.id;
    setLoading(true);
    await Api.saveLead( values, id );

    let allLeads = [...leads ];
    const index = allLeads.findIndex( lead => lead.id === id );

    allLeads[index] = { ...allLeads[index], ...values }
    setLeads(allLeads);
    message.success('Lead note has been successfully updated.');

    setLoading(false);
    setAddNote(false);
  }

  const initialValues = {
    note: note || ''
  };


  if ( addNote ) {
    return (
      <Spin spinning={loading} tip="Saving note..." size="large">
      <div className="form--default">
      <Form name="save-note" layout="vertical" colon={false} onFinish={handleSubmit} initialValues={initialValues}>

        <Form.Item
          name="note"
          label="Please enter your note below, We support markdown syntax for this field."
          autoFocus
          rules={[
            { required: true, message: 'Please enter some note for this lead' },
          ]}
        >
          <Input.TextArea rows={10} placeholder="Enter your note here" />
        </Form.Item>

        <div className="footer right-aligned">
          <Form.Item style={{ marginBottom: 0 }}>
            <Button type="primary" htmlType="submit">Save Note</Button>
            <Button type="primary" style={{ marginLeft: 10 }} ghost onClick={ () => { setAddNote(false); } }>Cancel</Button>
          </Form.Item>
        </div>

      </Form>
      </div>
      </Spin>
    )
  }

  if ( ! addNote && ! note ) {
    return (
      <EmptyNote>
        <p>You haven't added any note for this lead yet.</p>
        <Button type="primary" style={{ marginTop: 10 }} onClick={ () => { setAddNote(true); } }>Add Note</Button>
      </EmptyNote>
    )
  } else {
    return (
      <Fragment>
        <MarkdownWrapper>
          <ReactMarkdown source={ note } />
        </MarkdownWrapper>
        <Button type="primary" style={{ marginTop: 10 }} onClick={ () => { setAddNote(true); } }>Update Note</Button>
      </Fragment>
    )
  }


}


export default Note;



const EmptyNote = styled.div`
  margin-top: 20px;
  padding: 30px;
  background: #EEE;
  border-radius: 10px;
  p {
    font-size: 16px;
  }
`;


const MarkdownWrapper = styled.div`
  border: 1px solid #00BCD4;
  padding: 30px;
  border-radius: 10px;

  p {
    margin-bottom: 10px;
    &:last-child { margin-bottom: 0; }
  }

  h1, h2, h3, p, ol, ul, hr {
    margin: 10px;
  }

  h1 {
    font-size: 36px;
    line-height: 100%;
    font-weight: 600;
  }

  h2 {
    font-size: 28px;
    line-height: 100%;
    font-weight: 600;
  }

  h3 {
    font-size: 22px;
    line-height: 100%;
    font-weight: 600;
  }

  ol, ul {
    list-style: decimal;
    margin-left: 30px;
    li {

    }
  }

`;

