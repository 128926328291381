import React, { Suspense } from 'react';
import styled from 'styled-components';
import { DefaultLayout } from 'global/layouts';
import { Link } from 'react-router-dom';
import { Calendar, Button, Badge } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import Loading from 'global/components/Loading';
import { useRecoilValue } from 'recoil';
import { leadsState } from 'global/states';
import { format, isSameMonth } from 'date-fns';
import PageHeader from 'global/components/PageHeader';





const CalendarPage = (props) => {
  const leads = useRecoilValue(leadsState);

  const onPanelChange = (e) => {
    console.log("onPanelChange", e);
  }


  const dateCellRender = (value) => {
    const dateFormat = 'MM-dd-yyyy';
    const date = format( value.toDate(), dateFormat );
    const events = [];
    leads.forEach( item => {
      if ( item.duedate ) {
        const duedate = format( new Date(item.duedate.seconds * 1000), dateFormat );
        if ( duedate === date ) {
          events.push({ type: 'success', content: item.name, id: item.id })
        }
      }
    });
    return (
      <div>
        {
          events.map(item => (
            <div key={item.content}>
              <Badge status={item.type} text={<Link to={`/leads/${item.id}`}>{item.content}</Link>} />
            </div>
          ))
        }
      </div>
    );
  }



  const monthCellRender = (value) => {
    const date = value.toDate();
    const events = [];
    leads.forEach( item => {
      if ( item.duedate ) {
        const duedate = new Date(item.duedate.seconds * 1000);
        if ( isSameMonth(duedate, date) ) {
          events.push({ type: 'success', content: item.name, id: item.id })
        }
      }
    });
    return (
      <div>
        {
          events.map(item => (
            <div key={item.content}>
              <Badge status={item.type} text={<Link to={`/leads/${item.id}`}>{item.content}</Link>} />
            </div>
          ))
        }
      </div>
    );
  }


  return (
    <DefaultLayout
      header={
        <PageHeader
          title="Calendar"
          subTitle="All events and appointments"
          onBack={ () => { props.history.push('/') } }
          buttons={[
            <Link key="add-lead" to="/leads/create"><Button type="primary" icon={<PlusOutlined />}>Add New Lead</Button></Link>
          ]}
        />
      }
    >

      <CalendarContainer>
        <Calendar onPanelChange={onPanelChange} dateCellRender={ dateCellRender } monthCellRender={ monthCellRender } />
      </CalendarContainer>

    </DefaultLayout>
  )

}

const CalendarSuspense = (props) => {
  return (
    <Suspense fallback={<DefaultLayout><Loading message="Loading Calendar..." /></DefaultLayout>}>
      <CalendarPage history={props.history} />
    </Suspense>
  )
}

export default CalendarSuspense;

const CalendarContainer = styled.div`
  .ant-picker-calendar-full .ant-picker-panel, .ant-picker-calendar-header {
    background: #f7f8fa;
  }
`;
