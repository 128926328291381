import React, { Suspense, useState } from 'react';
import { DefaultLayout } from 'global/layouts';
import { DataContainer } from 'global/components/UI';
import { Link } from 'react-router-dom';
import PageHeader from 'global/components/PageHeader';
import Loading from 'global/components/Loading';

import { PlusOutlined } from '@ant-design/icons';
import { Row, Col, Button, Input, Radio } from 'antd';

import { useRecoilValue } from 'recoil';
import { leadsState, groupsState } from 'global/states';
import { DefaultList, StatusList } from 'pages/leads/LeadList';


const LeadsData = (props) => {
  const { searchText, layout, group } = props;
  const allLeads = useRecoilValue(leadsState);
  const leads = allLeads.filter( lead => lead.group === group.id );

  const groups = useRecoilValue(groupsState);

  let filteredLeads = [];
  if ( searchText ) {
    filteredLeads = leads.filter( lead => {
      const search = searchText.toLowerCase();
      const duedate = new Date(lead.duedate.seconds * 1000).toString();

      if ( lead.name.toLowerCase().indexOf(search) > -1 ||
        ( lead.email.toLowerCase().indexOf(search) > -1) ||
        ( lead.location && lead.location.toLowerCase().indexOf(search) > -1 ) ||
        ( lead.status && lead.status.toLowerCase().indexOf(search) > -1 ) ||
        ( lead.phone && lead.phone.indexOf(search) > -1 ) ||
        ( duedate && duedate.toLowerCase().indexOf(search) > -1 )
      ) {
        return true;
      } else { return false; }
    });
  } else {
    filteredLeads = leads;
  }


  if ( ! searchText && filteredLeads.length === 0 ) {
    return (
      <DataContainer
        title="All Leads"
        text="You haven't created any leads yet. As soon as you create any lead it will appear here."
        extra={ <Link to="/leads/create"><Button type="primary" icon={<PlusOutlined />}>Create New Lead</Button></Link> }
      />
    )
  } else if ( searchText && filteredLeads.length === 0 ) {
    return (
      <DataContainer
        title="No Leads Found"
        text={`We couldn't find any leads matching your search term: ${searchText}`}
      />
    )
  } else {
    if ( layout === 'status' ) {
      return (
        <Row>
        <Col xs={{ span: 24 }} md={{ span: 20, offset: 2 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 18, offset: 3 }}>
          <StatusList leads={filteredLeads} groups={groups} />
        </Col>
        </Row>
      )
    } else {
      return (
        <Row>
        <Col xs={{ span: 24 }} md={{ span: 20, offset: 2 }} lg={{ span: 18, offset: 3 }} xl={{ span: 18, offset: 3 }} xxl={{ span: 18, offset: 3 }}>
          <DefaultList leads={filteredLeads} />
        </Col>
        </Row>
      )
    }
  }

}


const AllLeads = (props) => {
  const [ searchText, setSearchText ] = useState('');

  const allLayouts = [ 'card', 'status' ];
  let leadLayout = localStorage.getItem('lead-layout-groups');
  if ( ! allLayouts.includes(leadLayout) ) { leadLayout = 'card'; }

  const [ layout, setLayout ] = useState(leadLayout);
  const groups = useRecoilValue(groupsState);
  const group = groups.find( group => group.id === props.match.params.id );

  const changeLeadsLayout = (event) => {
    setLayout(event.target.value);
    localStorage.setItem('lead-layout-groups', event.target.value);
  }

  return (
    <DefaultLayout
      header={
        <PageHeader
          title={ group.name }
          subTitle="All Leads"
          onBack={ () => { props.history.push(`/groups/${group.id}`) } }
          buttons={[
            <Radio.Group
              key="layout"
              defaultValue={ layout }
              buttonStyle="solid"
              style={{ marginRight: 10 }}
              onChange={ changeLeadsLayout }
            >
              <Radio.Button value="card">Card</Radio.Button>
              <Radio.Button value="status">Status</Radio.Button>
            </Radio.Group>,
            <Input.Search
              key="search"
              placeholder="Search Lead"
              onSearch={ setSearchText }
              style={{ maxWidth: 300, width: 200 }}
              enterButton
              allowClear
            />,
            <Link to={{ pathname: `/leads/create`, state: { groupId: group.id } }} key="createLead"><Button type="primary" icon={<PlusOutlined />}>Create New Lead</Button></Link>
          ]}
        />
      }
    >

      <LeadsData searchText={searchText} layout={layout} group={group} />

    </DefaultLayout>
  )

}


const ShowGroupLeadsSuspense = (props) => {
  return (
    <Suspense fallback={<DefaultLayout><Loading message="Loading..." /></DefaultLayout>}>
      <AllLeads {...props} />
    </Suspense>
  )
}

export default ShowGroupLeadsSuspense;


